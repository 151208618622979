import { environment } from "src/environments/environment";
import { dynamic_registry } from "./utils/dynamic_registry";
import { OTLPConnection } from "@overa/shared";

/**
 * Represents the application configuration.
 */
export const AppConfig = {
  /**
   * The name of the application.
   */
  appName: environment.app,

  /**
   * The version of the application.
   */
  appVersion: environment.version,

  /**
   * Custom configuration function that modifies the config data based on app settings.
   * @param configData - The configuration data object.
   * @param appSettingsData - The application settings data.
   *
   * To encrypt the communication between the client and the server, you can use the following code:
   * configData["overa-security"].kv = {
   *  "localhost:5100": "!*%NZZ{}qXEPGC@.",
   * };
   *
   */
  customConfig: (configData: { [key: string]: any }, appSettingsData: any) => {

    configData["overa-dynamic"]["registry"] = dynamic_registry;

    configData["overa-components"]["overa-error-dialog"].showToast =
      environment.production;
    configData["overa-shared"].useGoldenLayout = true;
    configData["overa-shared"].allwaysOpenNewTabOnGL = false;
    configData["overa-shared"].otlpConnection = new OTLPConnection({
      url: 'http://localhost:32790/v1/traces'
    });
    configData["overa-shared"].lokiUrl = 'http://localhost:3100';

    configData["overa-dynamic"].dynamicEntityParentRoute = "/dynamic";
    configData["overa-dynamic"].dynamicListParentRoute = "/dynamic/dynamicView";
    configData["overa-dynamic"].dynamicFormParentRoute = "/m/dynamicForm";
    configData["overa-notification"].notificationBaseUrl = null;
    configData["overa-security"].silent_redirect_uri = ""; // disable iframe silent renew 
    configData["overa-security"].sessionChecker = false;
    configData["overa-security"].showDebug = false;
    configData["overa-security"].showInterceptorsLog = false;
    (configData["overa-components"]["overa-error-dialog"] =
      configData["overa-components"]["overa-error-dialog"] ||
      {}).showErrorMessages = false;
      configData["overa-filemanager"] = {
        filemanagerBaseUrl: appSettingsData.storageApiUrl,
        isDebug: true,
      };

  },
};
