import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { ActivityBaseComponent } from "../../shared/activity-base.component";
import { Filters } from "src/app/models/filters.model";
import { MeasurementUserService } from "src/app/services/measurementUser.service";
import {
  OveraEchartComponent,
  OveraEchartData,
  OveraEchartVBarOptions,
  ResponsiveChartOptions,
} from "@overa/components/echart";

@Component({
  selector: "app-percentage-users-telemetry",
  templateUrl: "./percentageUsersTelemetry.component.html",
  styleUrls: ["./percentageUsersTelemetry.component.scss"],
})
export class PercentageUsersTelemetryComponent extends ActivityBaseComponent {
  @ViewChild("chart")
  chart?: OveraEchartComponent;

  chartOptions: OveraEchartVBarOptions = new OveraEchartVBarOptions();
  chartData?: OveraEchartData;
  responsiveOptions?: ResponsiveChartOptions<OveraEchartVBarOptions>[];

  legend: string[] = [];

  constructor(private measurementUserService: MeasurementUserService) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.filterInit.dataRangeDays = 7;
    //set chart
    this.chartOptions.title.text = $localize`:@@VIEWS.PERCENTAGE_USERS_TELEMETRY.TITLE:% Usuarios con telemetría`;

    this.chartOptions.tooltip.formatter = (params: any) => {
      let tooltip = "";

      if (Array.isArray(params)) {
        const totalUsers = params.reduce(
          (acc, param) => acc + (param.data as number),
          0
        );

        tooltip = params
          .map((param) => {
            return `${param.marker} ${param.seriesName}: ${param.data} - ${(
              ((param.data as number) / totalUsers) *
              100
            ).toFixed(2)}%`;
          })
          .join("<br>");
      }
      return tooltip;
    };

    this.chartOptions.seriesOption.stack = "total";

    this.chartOptions.legend.padding = [40, 0];

    this.chartOptions.legend.data = this.legend;

    this.responsiveOptions = [
      {
        condition: (width, height, ratio) => width < 500,
        changes: (chartOptions, chart) => {
          let calculatedChartOptions =
            this.chart?.chartInstance?.getOption() as any;
          let titleHeight = 40; // No se puede obtener directamente, pero sí el tamaño de letra
          let legendItemHeight = calculatedChartOptions?.legend[0].itemHeight;
          let legendGapHeight = calculatedChartOptions?.legend[0].itemGap;
          let legendItemsNumber = this.legend.length;
          let legendHeight =
            legendItemsNumber * (legendItemHeight + legendGapHeight);
          chartOptions.grid.top = titleHeight + legendHeight;

          chart.resize(300);
        },
      },
      {
        condition: (width, height, ratio) => width > 500,
        changes: (chartOptions, chart) => {
          chartOptions.grid.top = 60; // Default value
          chart.resize(400); // Default value
        },
      },
    ];
  }

  loadData(filters: Filters) {
    this.loading = true;

    this.measurementUserService.getUsersByDays(filters).subscribe((res) => {
      this.chartOptions.yAxis.max = res.info
        ? res.info["totalUsers"]
        : "dataMax";

      this.legend = [];
      res.series?.forEach((serie) => {
        if (serie.name != undefined) {
          this.legend.push(serie.name.toString());
        }
      });
      this.chartOptions.legend.data = this.legend;

      this.chartData = res;

      this.loading = false;
    });
  }

  onChartResize() { }
}
