import { Component, ElementRef, Renderer2, ViewChild, AfterViewInit, Inject, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { ACCOUNT_SERVICE_TOKEN, IAccountService } from "@overa/shared";

@Component({
    selector: "overa-iframe",
    templateUrl: "./iframeOveraComponent.component.html",
    styleUrls: ["./iframeOveraComponent.component.css"],
})
export class IframeOveraComponent implements AfterViewInit {
    @ViewChild('iframeContainer', { static: false }) container!: ElementRef;
    @Input() iframeUrl!: string; // URL del iframe
    @Input() querySelectorsToRemove: string[] = []; // Array de selectores a eliminar
    @Input() dynamicStyles: { selector: string; styles: { [key: string]: string } }[] = [];
    @Input() useDynamicFilters: boolean = true; // Control de filtros dinámicos
    @Output() variablesData = new EventEmitter<any[]>(); // Para las variables
    @Output() adhocFiltersData = new EventEmitter<any[]>();// Para los filtros adhoc
    dynamicFiltersData: any = {}; // Datos generados por filtros dinámicos
    variables: any[] = []; // Variables dinámicas cargadas desde el iframe

    iframe!: HTMLIFrameElement;

    adhocFilters: {
        key: string;
        operator: string;
        value: string;
        operators: { label: string; value: string }[];
        selectedOperator?: string;
        selectedValue?: string;
    }[] = [];

    globalurl = "";
    timeRange: { from: string | null; to: string | null } = {
        from: null,
        to: null,
    };
    selectedQuickRange: any; // Rango rápido seleccionado
    quickRanges = [
        { label: 'Últimos 5 minutos', value: { from: 'now-5m', to: 'now' } },
        { label: 'Últimos 15 minutos', value: { from: 'now-15m', to: 'now' } },
        { label: 'Últimas 30 minutos', value: { from: 'now-30m', to: 'now' } },
        { label: 'Última 1 hora', value: { from: 'now-1h', to: 'now' } },
        { label: 'Hoy', value: { from: 'now/d', to: 'now' } },
        { label: 'Esta semana', value: { from: 'now/w', to: 'now' } },
    ];

    constructor(private readonly renderer: Renderer2, private readonly router: Router,
        @Inject(ACCOUNT_SERVICE_TOKEN) private readonly accountService: IAccountService) { }



    ngAfterViewInit() {
        // Crear y configurar iframe
        this.iframe = this.renderer.createElement('iframe');
        this.renderer.setAttribute(this.iframe, 'src', this.iframeUrl ?? '');


        this.renderer.setStyle(this.iframe, 'width', '100%');
        this.renderer.setStyle(this.iframe, 'height', '70vh');
        this.renderer.appendChild(this.container.nativeElement, this.iframe);

        // Esperar a que el iframe cargue completamente
        this.iframe.onload = () => {
            this.observeIframeDomChanges();
            this.getGrafanaTimeRange();
        };

    }
    applyQuickRange() {
        // Verificar que `selectedQuickRange` tiene un valor válido
        if (!this.selectedQuickRange || !this.selectedQuickRange.from || !this.selectedQuickRange.to) {
            console.error('selectedQuickRange no contiene valores válidos para "from" y "to".');
            return;
        }

        // Actualizar el rango de tiempo seleccionado
        this.timeRange = {
            from: this.selectedQuickRange.from,
            to: this.selectedQuickRange.to,
        };
        console.log('Quick Range aplicado:', this.timeRange);

        // Convertir `from` y `to` a timestamps en milisegundos
        const fromTimestamp = this.parseNowExpression(this.timeRange.from ?? '');
        const toTimestamp = this.parseNowExpression(this.timeRange.to ?? '');

        // Validar los timestamps generados
        console.log('Desde (timestamp):', fromTimestamp);
        console.log('Hasta (timestamp):', toTimestamp);

        // Construir el nuevo URL con los timestamps
        const baseUrl = this.iframe.contentWindow?.location.href.split('&from')[0] ?? this.iframeUrl;
        const updatedUrl = `${baseUrl}&from=${fromTimestamp}&to=${toTimestamp}`;

        console.log('URL actualizada con rango rápido:', updatedUrl);

        // Actualizar el iframe con el nuevo URL
        this.renderer.setAttribute(this.iframe, 'src', updatedUrl);
    }

    parseNowExpression(expression: string): number {
        const now = new Date();
        if (expression === 'now') {
            return now.getTime(); // Timestamp actual
        }

        const match = expression.match(/now([+-]\d+)([smhdw])/);
        if (match) {
            const value = parseInt(match[1], 10); // Número relativo
            const unit = match[2]; // Unidad (s, m, h, d, w)

            switch (unit) {
                case 's': // Segundos
                    return now.getTime() + value * 1000;
                case 'm': // Minutos
                    return now.getTime() + value * 1000 * 60;
                case 'h': // Horas
                    return now.getTime() + value * 1000 * 60 * 60;
                case 'd': // Días
                    return now.getTime() + value * 1000 * 60 * 60 * 24;
                case 'w': // Semanas
                    return now.getTime() + value * 1000 * 60 * 60 * 24 * 7;
            }
        }

        console.error(`Expresión "now" no válida: ${expression}`);
        return now.getTime(); // Valor predeterminado
    }
    applyTimeRange() {
        if (!this.timeRange.from || !this.timeRange.to) {
            console.error('Rango de tiempo no válido.');
            return;
        }

        // Convertir las fechas seleccionadas a timestamps en milisegundos
        const fromTimestamp = new Date(this.timeRange.from).getTime();
        const toTimestamp = new Date(this.timeRange.to).getTime();

        // Verificar que se han generado correctamente los timestamps
        console.log('Desde (timestamp):', fromTimestamp);
        console.log('Hasta (timestamp):', toTimestamp);

        // Construir el nuevo URL con los parámetros `from` y `to`
        const baseUrl = this.iframe.contentWindow?.location.href.split('&from')[0] ?? this.iframeUrl;
        const updatedUrl = `${baseUrl}&from=${fromTimestamp}&to=${toTimestamp}`;

        console.log('URL actualizada:', updatedUrl);

        // Actualizar el atributo `src` del iframe con el nuevo URL
        this.renderer.setAttribute(this.iframe, 'src', updatedUrl);
    }


    observeIframeDomChanges() {
        const iframeWindow = this.iframe.contentWindow;
        const iframeDocument = iframeWindow?.document;

        if (!iframeDocument) {
            console.error('No se pudo acceder al documento del iframe.');
            return;
        }

        // Configurar el MutationObserver
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList' || mutation.type === 'attributes') {
                    this.handleDomChanges(iframeDocument);
                }
            });
        });

        // Observar cambios en tdo el cuerpo del iframe
        observer.observe(iframeDocument.body, {
            attributes: true,
            childList: true,
            subtree: true,
        });

    }
    getGrafanaTimeRange() {
        const iframeWindow = this.iframe.contentWindow;
        if (!iframeWindow) {
            console.error('No se pudo acceder al iframe.');
            return;
        }

        try {
            const timeRange = iframeWindow.grafanaRuntime?.getDashboardTimeRange();
            if (timeRange) {
                console.log('Rango de tiempo obtenido:', timeRange);
                // Aquí puedes hacer algo con el rango de tiempo
                this.processTimeRange(timeRange);
            } else {
                console.warn('grafanaRuntime no está disponible o no se puede obtener el rango de tiempo.');
            }
        } catch (error) {
            console.error('Error al acceder a grafanaRuntime:', error);
        }
    }

    processTimeRange(timeRange: any) {
        // Procesa el rango de tiempo y haz algo con él
        console.log('Procesando rango de tiempo:', timeRange);
        // Por ejemplo, puedes emitirlo como un evento o almacenarlo en una propiedad
        this.dynamicFiltersData.timeRange = timeRange;
        console.log('Datos dinámicos actualizados:', this.dynamicFiltersData);
    }

    handleDomChanges(iframeDocument: Document) {
        // Detectar cambios en los filtros Ad-Hoc
        // Detectar cambios en los filtros Ad-Hoc
        const adhocFilterElements = iframeDocument.querySelectorAll(".gf-form[data-testid='AdHocFilterKey-key-wrapper']");

        const adhocFilters: {
            key: string;
            operator: string;
            value: string;
            operators: { label: string; value: string }[];
            selectedOperator?: string;
            selectedValue?: string;
        }[] = [];
        if (this.globalurl != this.iframe.contentWindow?.location.href) {
            console.log(this.iframe.contentWindow?.location.href ?? "");
            this.globalurl = this.iframe.contentWindow?.location.href ?? ";"
        }


        adhocFilterElements.forEach((keyElement) => {
            const operatorElement = keyElement.nextElementSibling?.querySelector("label.query-segment-operator");
            const operatorLabel = operatorElement?.textContent?.trim() ?? "";

            const valueElement = keyElement.nextElementSibling?.nextElementSibling?.querySelector("label.query-segment-value");
            const valueLabel = valueElement?.textContent?.trim() ?? "";

            const keyLabel = keyElement.querySelector("button")?.textContent?.trim() ?? "";

            if (keyLabel) {
                adhocFilters.push({
                    key: keyLabel,
                    operator: operatorLabel || "=", // Valor por defecto si no se encuentra ninguno
                    value: valueLabel || "",
                    operators: [
                        { label: "Igual", value: "=" },
                        { label: "Distinto", value: "!=" },
                        { label: "Mayor que", value: ">" },
                        { label: "Menor que", value: "<" },
                        { label: "AND", value: "AND" },
                    ],
                    selectedOperator: String(operatorLabel),
                    selectedValue: valueLabel || "",
                });

            }
        });

        // Actualizar los filtros Ad-Hoc en Angular
        if (JSON.stringify(this.adhocFilters) !== JSON.stringify(adhocFilters)) {
            this.adhocFilters = adhocFilters;
            console.log('Filtros Ad-Hoc actualizados:', this.adhocFilters);
            console.log('Operadores:', this.adhocFilters[0].operators);
            console.log('Operador seleccionado:', this.adhocFilters[0].selectedOperator);

            // Llamar a una función personalizada si es necesario
            this.onFiltersUpdated(this.adhocFilters);
        }

        // Detectar cambios en las variables dinámicas
        const variableElements = iframeDocument.querySelectorAll(".variable-link-wrapper");
        const variablesMap = new Map();

        variableElements.forEach((v: any) => {
            const reactPropsKey = Object.keys(v).find(s => s.startsWith("__reactProps"));
            const reactProps = reactPropsKey ? (v as any)[reactPropsKey] : undefined;
            const variableObject = reactProps?.children?._owner?.memoizedProps?.variable;

            if (variableObject) {
                const variableName = variableObject.name;
                const options = variableObject.options;
                const multi = variableObject.multi;

                const parsedOptions = options.map((o: any) => ({
                    value: o.value,
                    text: o.text,
                    selected: o.selected,
                }));

                variablesMap.set(variableName, { multi, options: parsedOptions });
            }
        });

        const variablesObject = Array.from(variablesMap, ([name, values]) => ({
            name,
            multi: values.multi,
            options: values.options,
        }));

        this.variables = variablesObject.map((v: any) => ({
            name: v.name,
            multi: v.multi,
            options: v.options,
            selectedValues: v.multi
                ? v.options.filter((option: any) => option.selected)
                : null,
            selectedValue: !v.multi
                ? v.options.find((option: any) => option.selected)?.value
                : null,
        }));
        if (!this.useDynamicFilters) {
            this.generateDynamicFiltersData(this.variables, adhocFilters);
        }
        this.adjustIframeStylesAndSelectors();


    }
    private generateDynamicFiltersData(
        variables?: any[],
        adhocFilters?: {
            key: string;
            operator: string;
            value: string;
            operators: { label: string; value: string; }[];
            selectedOperator?: string;
            selectedValue?: string;
        }[]
    ): void {
        if (variables) {
            this.variablesData.emit(variables); // Emitir variables
        }
        if (adhocFilters) {
            this.adhocFiltersData.emit(adhocFilters); // Emitir filtros adhoc
        }
    }

    private adjustIframeStylesAndSelectors(): void {
        const iframeWindow = this.iframe.contentWindow;
        const iframeDocument = iframeWindow?.document;

        if (!iframeDocument) {
            console.error('No se pudo acceder al documento del iframe.');
            return;
        }

        // 1. Aplicar estilos dinámicos
        if (this.dynamicStyles.length > 0) {
            this.dynamicStyles.forEach(styleConfig => {
                const elements = iframeDocument.querySelectorAll(styleConfig.selector);
                if (elements.length > 0) {
                    elements.forEach(element => {
                        const htmlElement = element as HTMLElement;
                        Object.entries(styleConfig.styles).forEach(([key, value]) => {
                            htmlElement.style[key as any] = value;
                        });
                    });
                    console.log(`Estilos aplicados a los elementos con selector "${styleConfig.selector}".`);
                } else {
                    console.warn(`No se encontraron elementos con el selector "${styleConfig.selector}" para aplicar estilos.`);
                }
            });
        }

        // 2. Eliminar elementos por selectores
        if (this.querySelectorsToRemove.length > 0) {
            this.querySelectorsToRemove.forEach(selector => {
                const elements = iframeDocument.querySelectorAll(selector);
                if (elements.length > 0) {
                    elements.forEach(element => element.remove());
                    console.log(`Elementos con selector "${selector}" han sido eliminados.`);
                } else {
                    console.warn(`No se encontraron elementos con el selector "${selector}" para eliminar.`);
                }
            });
        }
    }

    // Función para eliminar un filtro
    removeFilter(index: number) {
        this.adhocFilters.splice(index, 1);
        console.log('Filtro eliminado. Filtros actuales:', this.adhocFilters);
    }

    onFiltersUpdated(filters: any[]) {
        // Aquí puedes manejar la lógica cuando los filtros cambian
        console.log('Filtros cambiaron:', filters);
    }

    // Función para aplicar los filtros
    applyFilters() {
        if (this.iframe?.src == undefined) {
            console.error('El iframe o su URL no están definidos.');
            return;
        }

        try {
            const baseUrl = this.iframe.contentWindow?.location.href.split('&v')[0] ?? ""; // Obtener la URL base (sin parámetros)
            const params = new URLSearchParams();

            // Construir los parámetros para las variables dinámicas
            const newUrl = this.variables.length != 0 ? this.setQueryFilters(params, baseUrl) : this.setAdhocFilters(baseUrl);
            console.log('Nueva URL generada:', newUrl);

            // Actualizar el iframe con la nueva URL
            this.renderer.setAttribute(this.iframe, 'src', newUrl);
        } catch (error) {
            console.error('Error al actualizar la URL del iframe:', error);
        }
    }

    private setAdhocFilters(baseUrl: string) {

        let filterParams = '';
        this.adhocFilters.forEach(filter => {
            if (filter.selectedOperator && filter.selectedValue) {
                // Construir el filtro en el formato requerido por Grafana
                const filterParam = `${filter.key}|${filter.selectedOperator}|${encodeURIComponent(filter.selectedValue)}`;
                filterParams += `&var-Filters=${filterParam}`;
            }
        });

        // Generar la nueva URL combinando la base con los parámetros
        const newUrl = `${baseUrl}&${filterParams.toString()}`;
        return newUrl;
    }

    private setQueryFilters(params: URLSearchParams, baseUrl: string) {
        this.variables.forEach(variable => {
            if (variable.multi) {
                // Añadir múltiples valores para el mismo parámetro
                variable.selectedValues?.forEach((value: any) => {
                    params.append(`var-${variable.name}`, value.value);
                });
            } else if (variable.selectedValue) {
                // Añadir un solo valor para el parámetro
                params.append(`var-${variable.name}`, variable.selectedValue.value);
            }
        });
        const newUrl = `${baseUrl}&${params.toString()}`;
        return newUrl;
    }
    removeOfDOM() {
        const iframeWindow = this.iframe.contentWindow;
        const iframeDocument = iframeWindow?.document;

        if (!iframeDocument) {
            console.error('No se pudo acceder al documento del iframe.');
            return;
        }
        const navToolbar = iframeDocument.querySelector('[data-testid="data-testid Nav toolbar"]');

        // Verificar si el div existe
        if (navToolbar) {
            // Eliminar el div del DOM
            navToolbar.remove();
            console.log('El div con data-testid "data-testid Nav toolbar" ha sido eliminado.');
        } else {
            console.warn('No se encontró el div con data-testid "data-testid Nav toolbar".');
        }
        const scrollbarView = iframeDocument.querySelector('[data-testid="data-testid navigation mega-menu"]');

        // Verificar si el div existe
        if (scrollbarView) {
            // Guardar el contenido del div "scrollbar-view" temporalmente
            scrollbarView.remove();
            console.log('Se ha eliminado todo excepto el div con la clase "scrollbar-view".');
        } else {
            console.warn('No se encontró el div con la clase "scrollbar-view".');
        }
        const targetDiv = iframeDocument.querySelector('.css-1bgjk0t');

        // Verificar si el div existe
        if (targetDiv) {
            // Eliminar el div del DOM
            targetDiv.remove();
            console.log('El div con la clase "css-1bgjk0t" ha sido eliminado.');
        } else {
            console.warn('No se encontró el div con la clase "css-1bgjk0t".');
        }
        const targetElements = iframeDocument.querySelectorAll('.css-60onds');
        if (targetElements.length > 0) {
            targetElements.forEach((element) => {
                const htmlElement = element as HTMLElement;
                // Eliminar el padding-top
                htmlElement.style.paddingTop = '0';
            });
            console.log('El padding-top de los elementos con clase "css-60onds" ha sido eliminado.');
        } else {
            console.warn('No se encontraron elementos con la clase "css-60onds".');
        }
        const dashboardSubmenu = iframeDocument.querySelector('section[aria-label="Dashboard submenu"]');

        // Verificar si la sección existe
        if (dashboardSubmenu) {
            // Aplicar el estilo `hidden`
            (dashboardSubmenu as HTMLElement).style.display = 'none';
            console.log('La sección con aria-label="Dashboard submenu" ha sido ocultada.');
        } else {
            console.warn('No se encontró la sección con aria-label="Dashboard submenu".');
        }
    }

}