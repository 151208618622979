import "@webcomponents/custom-elements";
import "@angular/localize/init";

import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = () => {
      // Disable console.log in production
    };
  }
} else if (window) {
  (window as any).ng = {};
}


platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((error) => {
    console.error("MAIN.TS - Error: ", error instanceof Error ? error.message : error);
  });
