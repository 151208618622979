import {
    Component,
    ViewChild,
    inject,
} from "@angular/core";

import { OveraLayoutComponent } from "@overa/base";

import { Permissions } from "@overa/security";

import { Menu } from "@overa/security";

import { Constants } from "../utils/constants";
import { environment } from "src/environments/environment";

import { GoldenLayoutHostComponent } from "./golden-layout/golden-layout-host.component";
import { GoldenLayoutManagerService } from "./golden-layout/golden-layout-manager.service";

import { LayoutModeService } from "../services/layout-mode.service";
import { ViewManagerService } from "../services/view-manager.service";


@Component({
    selector: "app-layout",
    templateUrl: "./layout.component.html",
    styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent extends OveraLayoutComponent {
    static override className = "LayoutComponent";

    override languages: { key: string; translateKey: string }[] = [
        { key: "es", translateKey: "Español" },
        { key: "en", translateKey: "English" },
    ];

    viewGolden: boolean = false;

    _goldenLayoutManagerService = inject(GoldenLayoutManagerService);

    @ViewChild("goldenLayoutHost")
    private readonly _goldenLayoutHostComponent!: GoldenLayoutHostComponent;

    layoutModeService = inject(LayoutModeService);
    viewManagerService = inject(ViewManagerService);

    allwaysOpenNewTabOnGL: boolean = this.config.getModuleConfig(
        "overa-shared",
        "allwaysOpenNewTabOnGL"
    );

    isGoldenLayoutView: boolean = false;

    showGlobalFilter = false;

    version!: string;

    override init = () => {
        if (!environment.production || environment.version.endsWith("-stg")) {
            this.version = environment.version;
        } else {
            this.version = "?";
            console.log(`LAYOUT-COMPONENT - ${environment.app} ${environment.version}`);
        }

        // this.notificationService.getCount(NOTIFICATION_TYPE.Internal).subscribe();
        // this.notificationService.notificationsNumber.subscribe((notif) => {
        //     this.setNotificationsCircle(notif);
        // });

        this.isGoldenLayoutView = this.layoutModeService.getGoldenLayoutMode();

        this.layoutModeService.isGoldenLayout$.subscribe((mode) => {
            this.isGoldenLayoutView = mode;
        });

    };

    override afterViewInit = () => {
        if (this._goldenLayoutHostComponent) {
             this._goldenLayoutManagerService.initialize(this._goldenLayoutHostComponent);
           // this._goldenLayoutManagerService.initialize(this._goldenLayoutHostComponent, this.disableTabs);
        } else {
            throw new Error("Golden Layout Host Component not found");
        }
    };

    // START MENU MANAGEMENT

    override async subscribeMenus() {
        const defaultMenuMaintenanceIcon = Constants.DEFAULT_MENU_MAINTENANCE_ICON;
        this.accountService
            .getUserMenusChangedEvent()
            .subscribe((menuItems: Menu[]) => {
                if (menuItems) {
                    const hasMenuAccess = this.getHasMenuAccesValue();
                    if (
                        hasMenuAccess &&
                        !menuItems
                            ?.map((m: Menu) => m.uiUrl)
                            .includes("/core/security/menu")
                    ) {
                        this.pushMenuForSecurityMenu(defaultMenuMaintenanceIcon, menuItems);
                    }

                    menuItems = this.processMenuItemsByParentOrUnique(menuItems);
                    const finalItems: Menu[] = this.processFinalMenuItems(menuItems);

                    this.menuItems = [...finalItems];
                    this.calculateMenubarItems();
                }
            });
        await this.accountService.loadUserMenus();
    }

    private processFinalMenuItems(menuItems: Menu[]) {
        const finalItems: Menu[] = [];
        menuItems.forEach((menu: Menu) => {
            if (!finalItems.map((x) => x.id).includes(menu.id)) {
                finalItems.push(menu);
            }
        });
        return finalItems;
    }

    private processMenuItemsByParentOrUnique(menuItems: Menu[]) {
        menuItems = menuItems.filter((m: Menu) => !m.parentId);

        menuItems?.forEach((m: Menu) => {
            if (m.uiUrl) {
                m.type = "link";
            }
            if (m.children && m.children.length) {
                m.type = "sub";
                m.collapse = "child" + m.id;
            }
            m.isVisible = true;
        });
        return menuItems;
    }

    private pushMenuForSecurityMenu(
        defaultMenuMaintenanceIcon: string,
        menuItems: Menu[]
    ) {
        const menuItem = new Menu();
        menuItem.uiUrl = "/core/security/menu";
        menuItem.name = "Menu";
        menuItem.svg = defaultMenuMaintenanceIcon;
        menuItem.toolTip = "Menu";

        menuItems.push(menuItem);
    }

    private getHasMenuAccesValue() {
        let hasMenuAccess = this.accountService.currentUser?.isGlobalAdmin;
        if (!hasMenuAccess) {
            hasMenuAccess = this.accountService.userHasPermission(
                Permissions.PERMISSIONS_DATA["viewMenus"].name
            );
        }
        return hasMenuAccess;
    }

    //START SECTION MENUBAR ITEMS
    override calculateMenubarItems() {
        console.log("LAYOUT-COMPONENT - CALCULATE MENUBAR ITEMS");

        this.languages = Constants.BASIC_LANGUAGES_MENU;

        this.setSelectedLanguage();

        const langs: any = this.getLangMenu();

        const tenants: any = this.getTenantMenu();

        const user: any = this.getUserMenu();

        const security: any = this.getSecurityMenu();

        this.setUserMenuChilds(user, security);

        this.setBasicMenuBarItemsByTenant(tenants, langs, user);

        const menuItemsFromService: any[] = [
            ...this.menuItems.filter((x) => x.isNavIcon || x.parentId),
        ];

        menuItemsFromService.forEach((menu) => {
            this.processMenuItem(menu);
        });
        this.menubarItems = [...this.menubarItems, ...menuItemsFromService];
    }

    private processMenuItem(menu: any) {
        if (menu.componentName == "GlobalFiltersComponent") {
            menu.command = (event: any) => {
                this.showGlobalFilter = !this.showGlobalFilter;
            };
        } else if (menu.uiUrl) {
            menu.command = (event: any) => {
                this.viewManagerService.navigateByUrlToView(menu.uiUrl);
                // this.router.navigateByUrl(menu.uiUrl);
            };
        }
        if (menu.children?.length) {
            menu.children.forEach((c: any) => {
                if (c.showName) {
                    c.label = c.name;
                }
                if (c.uiUrl) {
                    c.command = (event: any) => {
                        this.viewManagerService.navigateByUrlToView(c.uiUrl);
                        // this.router.navigateByUrl(c.uiUrl);
                    };
                }
            });
        }
        menu.items = menu.children;
        if (menu.parentId) {
            menu.position = "left";
        }
    }

    private setBasicMenuBarItemsByTenant(tenants: any, langs: any, user: any) {
        if (tenants && tenants.items && tenants.items.length > 1) {
            this.menubarItems = [tenants, langs, user];
        } else {
            this.menubarItems = [langs, user];
        }
    }

    private setUserMenuChilds(user: any, security: any) {
        if (
            this.accountService.currentUser?.isAdmin ||
            this.accountService.currentUser?.isGlobalAdmin
        ) {
            user.items = [security, ...user.items];
        }
    }

    private getSecurityMenu(): any {
        return {
            icon: "pi pi-shield",
            label: $localize`:@@common.security:Seguridad`,
            items: [
                {
                    label: $localize`:@@common.roles:Roles`,
                    icon: "pi pi-list",
                    command: () => {
                        this.viewManagerService.navigateByUrlToView("core/security/roles");
                    },
                },
                {
                    label: $localize`:@@common.users:Usuarios`,
                    icon: "pi pi-users",
                    command: () => {
                        this.viewManagerService.navigateByUrlToView("core/security/users");
                    },
                },
            ],
        };
    }

    private getUserMenu(): any {
        return {
            icon: "pi pi-user",

            items: [
                {
                    label: $localize`:@@common.profile:Perfil`,
                    icon: "pi pi-id-card",
                    command: () => {
                        this.viewManagerService.navigateByUrlToView("core/settings/profile");
                    },
                },
                {
                    label: $localize`:@@common.closesession:Cerrar Sesión`,
                    icon: "pi pi-sign-out",
                    command: () => {
                        this.viewManagerService.navigateByUrlToView("core/settings/profileLogOut");
                    },
                },
            ],
        };
    }

    private getTenantMenu(): any {
        return {
            type: "dropdown",
            label: $localize`:@@common.tenant:Tenant`,
            selectedId: this.SSMService.currentTenant.id,
            command: (key: any) => {
                const newTenant = this.availableTenants.find((t) => t.id === key);
                if (newTenant) {
                    this.authService.onTenantChanged(newTenant);
                }
            },
            items: this.availableTenants?.map((l) => {
                return { id: l.id, label: l.displayName };
            }),
        };
    }

    private getLangMenu(): any {
        return {
            type: "dropdown",
            label: $localize`:@@common.language:Lenguaje`,
            selectedId: this.selectedLanguage?.key,
            command: (key: any) => {
                if (!this.selectedLanguage || key != this.selectedLanguage.key) {
                    this.selectedLanguage = this.languages.find((l) => l.key === key);
                    if (this.selectedLanguage) {
                        localStorage.setItem("locale", this.selectedLanguage.key);
                        location.reload();
                    }
                }
            },
            items: this.languages.map((l) => {
                return { id: l.key, label: l.translateKey };
            }),
        };
    }

    private setSelectedLanguage() {
        const currentLang = localStorage.getItem("locale");

        const f = this.languages.find((l) => l.key === currentLang);

        if (f) {
            this.selectedLanguage = { ...f };
        }
    }




    //END SECTION MENUBAR ITEMS
}
