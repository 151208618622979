import { environment } from "src/environments/environment";
import { dynamic_registry } from "./utils/dynamic_registry";

/**
 * Represents the application configuration.
 */
export const AppConfig = {
  /**
   * The name of the application.
   */
  appName: environment.app,

  /**
   * The version of the application.
   */
  appVersion: environment.version,

  /**
   * Custom configuration function that modifies the config data based on app settings.
   * @param configData - The configuration data object.
   * @param appSettingsData - The application settings data.
   *
   * To encrypt the communication between the client and the server, you can use the following code:
   * configData["overa-security"].kv = {
   *  "localhost:5100": "!*%NZZ{}qXEPGC@.",
   * };
   *
   */
  customConfig: (configData: { [key: string]: any }, appSettingsData: any) => {
    configData["overa-components"]["overa-error-dialog"].showToast =
      environment.production;
    configData["overa-dynamic"]["registry"] = dynamic_registry;
    configData["overa-dynamic"].dynamicEntityParentRoute = "/dynamic";
    configData["overa-dynamic"].dynamicListParentRoute = "/dynamic/dynamicView";
    configData["overa-dynamic"].dynamicFormParentRoute = "/m/dynamicForm";
    configData["overa-notification"].notificationBaseUrl = null;
  },
};
