import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { AccountService, RoleService } from "@overa/security";
import { ToastrService } from "ngx-toastr";
import { ConfirmationService, MenuItem } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable, of, Subject, Subscription } from "rxjs";
import { Column } from "src/app/models/colum.model";
import {
  ChangeFilter,
  FilterInit,
  FilterInstance,
  Filters,
} from "src/app/models/filters.model";
import { GeneralData } from "src/app/models/generalData.model";
import { GeneralDataService } from "src/app/services/generalData.service";
import {
  DefaultFilters,
  ActivityGlobals /* , InfoViewData */,
} from "src/app/utils/globals";
import { FilterDefaultDialog } from "./filterDefaultDialog/filterDefaultDialog.component";

import { IGeneralData } from "src/app/models/interfaces/iGeneralData.model";
//import { InfoView } from 'src/app/models/infoView.model';
import { NgxSpinnerService } from "ngx-spinner";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { isString } from "lodash";
import { FiltersService } from "src/app/services/filters.service";
import { RefreshService } from "src/app/services/refresh.service";
import { ACCOUNT_SERVICE_TOKEN, GLOBALS_TOKEN, IAccountService, IGlobals, NgxTranslateService } from "@overa/shared";
import { OveraMessagesService } from "@overa/components/info-dialog";

const GLOBALFILTERS_VIEWNAME = "GlobalFiltersComponent";
const FILTERTYPE_NAME = "filterType";
const GLOBAL = "global";
const DEFAULT = "default";

@Component({
  selector: "app-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.scss"],
  providers: [DialogService, ConfirmationService],
})
export class FiltersComponent implements OnInit, AfterViewInit {
  [key: string]: any;
  globalFilterSubscription: Subscription = new Subscription();
  viewFiltersSuscription: Subscription = new Subscription();

  @Input() filterInit!: FilterInit;
  @Output() initComponent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() loadingFilters: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() loadData: EventEmitter<Filters> = new EventEmitter<Filters>();
  @Output() filterChange: EventEmitter<ChangeFilter> =
    new EventEmitter<ChangeFilter>();
  @Output() columChange: EventEmitter<Column[]> = new EventEmitter<Column[]>();
  @Output() exportOnClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportModeChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() exportModeExportOnClick: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() viewModeChange: EventEmitter<IGeneralData> =
    new EventEmitter<IGeneralData>();
  @Output() validAllRequiredFilters: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Input() get selected_visibleFilters(): IGeneralData[] {
    return this._selectedVisibleFilters;
  }
  set selected_visibleFilters(elements: IGeneralData[]) {
    this._selectedVisibleFilters = elements;
    //this.showSelectedFilters(elements);
  }

  @Input() get selected_columns(): Column[] {
    return this._selectedColumns;
  }
  set selected_columns(val: Column[]) {
    this._selectedColumns = this.uniqueColumns.filter((col) =>
      val.includes(col)
    );
    this.columChange.emit(this._selectedColumns);
  }

  public defaultActionsIcon = "pi pi-file-o";
  public stringToArraySeparator = ";";
  public reloadDropdowns: { [key: string]: boolean } = {};

  protected _selectedViewMode!: IGeneralData;
  protected selectedFilters: Filters = {};
  protected selectedFiltersTest: Filters = {};
  protected defaultEmptyNameTranslation: string = "COMMONS.UNKNOWN";
  protected filterElement: any;
  protected defaultRequiredMark = "*";
  protected defaultConditionalRequiredMark = "(*)";
  protected formatLongTime = "longTime";
  protected formatShortTime = "HH:mm:ss";
  protected formatLongDate = "dd/MM/yyyy";
  protected formatShortDate = "HH:mm:ss";
  protected formatDateTime = "dd/MM/yyyy HH:mm:ss";
  protected defaultFormatDate = this.formatLongDate;
  protected defaultFormatTimeDate = this.formatShortDate;
  protected defaultFormatTime = this.formatShortTime;
  protected defaultFormatDateTime = this.formatDateTime;
  protected filtersNotChangeMode: string[] = [
    "viewMode",
    // 'startDate',
    // 'endDate',
    // 'deviceType',
    // 'deviceTypes',
  ];

  viewFiltersAll: FilterInstance[] = [];
  openedViewsFilters: FilterInstance[] = [];
  filterTypeViewFilters: FilterInstance[] = [];
  filtersOptions: { [key: string]: IGeneralData[] } = {};
  globalFilters: { [key: string]: IGeneralData[] | IGeneralData } = {};
  //userSavedFilters: DefaultFilters[] = [];
  showFilters: boolean = false;
  //groupedDepartments: SelectItemGroup[] = [];
  groupedDepartments = [];
  treeDepartments: any[] = [];
  uniqueColumns: Column[] = [];
  _selectedColumns: Column[] = [];
  actionsList?: MenuItem[];
  visibleFiltersDropdown: IGeneralData[] = [];
  _selectedVisibleFilters: IGeneralData[] = [];
  moreConfigs: MenuItem[] = [];
  exportModeEnabled!: boolean;
  controlDefaultFilter = false;

  //infoView: InfoView;
  ref!: DynamicDialogRef;
  filterWildCardHost: any;
  filterWildCardProcess: any;
  selected_wildcardFilterString!: string;
  isFiltersInit!: boolean;
  isViewInit: boolean = false;
  isFiltersLoading: boolean = false;
  userQuestionUpdate = new Subject<string>();
  // Hide filters tooltips in p-dropdowns when overlay is opened for selecting elements
  tooltipIsHidden = false;
  openDialogs = 0;
  // Shows if user has custom filter selections but not saved in DB
  unsavedFilter = false;
  // Structure for unsaved filters configuration
  unsavedFilterType: IGeneralData = {
    id: "custom",
    name: "Custom",
  };
  currentViewName!: string;
  isGlobalFilterView: boolean = false;

  constructor(
    protected generalDataService: GeneralDataService,
    protected spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private refreshService: RefreshService,
    private filtersService: FiltersService,
    private dialogService: DialogService,
    @Inject(ACCOUNT_SERVICE_TOKEN) private accountService: IAccountService,
    private roleService: RoleService,
    private translate: NgxTranslateService,
    protected overaMessagesService: OveraMessagesService,
    protected confirmationService: ConfirmationService,
    @Inject(GLOBALS_TOKEN) private readonly globals: IGlobals,
  ) {
    //this.infoView = InfoViewData.infoView;
    this.userQuestionUpdate
      .pipe(debounceTime(1000), distinctUntilChanged())
      ?.subscribe((value) => {
        console.log(value);
        this.setfilterWildcardString(value);
        if (this.isGlobalFilterView) {
          this.refreshService.refreshGlobalFilter(this.selectedFilters);
        }
      });

    this.initializeRefreshServiceSubscription();
  }

  private initViewFiltersSuscription() {
    this.viewFiltersSuscription =
      this.refreshService.refreshVisibleFilters$.subscribe(
        ({ viewFilters, add }: { viewFilters: any; add: boolean }) => {
          if (add) {
            this.openedViewsFilters.push(...viewFilters);
          } else {
            viewFilters.forEach((filter: any) => {
              const index = this.openedViewsFilters.findIndex(
                (item: any) => JSON.stringify(item) === JSON.stringify(filter)
              );

              if (index !== -1) {
                this.openedViewsFilters.splice(index, 1);
              }
            });
          }

          this.filterTypeViewFilters.forEach((element) => {
            element.visible = false;
          });

          const uniqueItems = this.openedViewsFilters.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.name === item.name)
          );

          uniqueItems.forEach((filter: any) => {
            this.filterTypeViewFilters.find(
              (f) => f.name == filter.name
            )!.visible = true;
          });
        }
      );
  }

  private initializeRefreshServiceSubscription() {
    this.globalFilterSubscription =
      this.refreshService.refreshGlobalFilter$.subscribe(
        ([generaldata]: any) => {
          if (this.isGlobalFilterView) {
            this.globals.user.currentGlobalFilters = generaldata;
          }

          if (
            (this.selectedFilters["filterType"] as IGeneralData).id == GLOBAL
          ) {
            this.resetPageFilters();
            this.initStartDateFilter(false);
            this.initEndDateFilter(false);

            this.selectedFilters = Object.fromEntries(
              Object.entries(this.globals.user.currentGlobalFilters!).filter(
                ([key, value]) => {
                  return (
                    key !== "filterType" &&
                    this.filterTypeViewFilters.some(
                      (filter) => filter.name === key
                    )
                  );
                }
              )
            );

            this.selectedFilters["filterType"] = {
              id: GLOBAL,
              name: this.capitalize(GLOBAL),
            };
            this.loadingData(this.selectedFilters, true);
            this.spinner.hide();
          }
        }
      );
  }

  ngOnDestroy(): void {
    if (!this.isGlobalFilterView) {
      this.refreshService.refreshVisibleFilters(this.viewFiltersAll, false);
    }
    this.globalFilterSubscription.unsubscribe();
    this.viewFiltersSuscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.isViewInit = true;
    this.loadInitView();
  }

  async ngOnInit(): Promise<void> {
    this.actionsList = this.filterInit.actionList;
    this.currentViewName = this.filterInit.viewName;

    if (
      this.globals.user &&
      (this.globals.user.isAdmin == true ||
        this.globals.user.isGlobalAdmin == true)
    ) {
      this.controlDefaultFilter = true;
      console.log(
        "filters this.controlDefaultFilter",
        this.controlDefaultFilter
      );
    }

    await this.getAllViewFilters();

    if (this.currentViewName == GLOBALFILTERS_VIEWNAME) {
      this.openedViewsFilters.push(
        ...this.filterTypeViewFilters.filter((f) => f.visible)
      );
      this.isGlobalFilterView = true;

      this.initViewFiltersSuscription();
    }

    if (!this.isGlobalFilterView) {
      this.refreshService.refreshVisibleFilters(this.viewFiltersAll, true);
    }

    this.getActions();
    // if (this.filterInit.autoloadData && (!this.filterInit.isLazyLoadData || !this.isGlobalFilterView)) {
    //   this.loadingData(this.filters);
    // } else {
    //   this.loading.emit(false);
    // }

    await this.initTypeFilterDropdownFilter();
    await this.initFilters(/* this.filterInit.notifyOnInitChangeFilter, true */);

    // Fill global filters selector and store data in the variable
    this.initVisibleFiltersDropdown();
    // Check which filters are selected by default
    this.updateSelectedVisibleFilters();

    if (
      this.filterInit.autoloadData &&
      (!this.filterInit.isLazyLoadData || !this.isGlobalFilterView)
    ) {
      this.loadingData(this.selectedFilters);
    } else {
      this.loading.emit(false);
    }
  }

  private async getAllViewFilters(filterType = null) {
    const filters = await this.filtersService.getViewFilters("").toPromise();
    this.viewFiltersAll = filters?.filter(
      (r) => r.view == this.currentViewName
    )!;

    const currentTypeFilter = this.viewFiltersAll.find(
      (f) =>
        f.name == "filterType" &&
        (filterType ? f.defaultValue == filterType : f["isInit"] == true)
    );
    this.filterTypeViewFilters = this.viewFiltersAll.filter(
      (f) => f["filterType"] == currentTypeFilter!.defaultValue
    );
  }

  protected getActions() {
    const labelExport = $localize`:@@ACTION.EXPORT:Export`;
    const labelReset = $localize`:@@ACTION.RESET:Reset`;
    const labelDeleteFilters = $localize`:@@ACTION.DELETE:Delete filters`;

    this.moreConfigs = [];

    if (
      this.filterInit.defaultButton &&
      this.controlDefaultFilter &&
      !this.exportModeEnabled
    ) {
      this.moreConfigs.push(
        ...[
          {
            label: "labelReset",
            icon: "pi pi-filter-slash",
            title: "labelDeleteFilters",
            command: () => {
              this.resetFilters();
            },
          },
        ]
      );
    }

    if (!this.isGlobalFilterView) {
      this.moreConfigs.push(
        ...[
          {
            label: "Reload",
            icon: "pi pi-refresh",
            title: "Reload",
            command: () => {
              this.reloadData();
            },
          },
        ]
      );
    }

    if (this.isAdmin()) {
      this.moreConfigs.push({
        label: "Save",
        icon: "pi pi-save",
        title: "Save",
        items: [
          {
            label: "Save user",
            icon: "pi pi-save",
            title: "Save user",
            command: () => {
              //this.saveUserFilter();
            },
          },
          {
            label: "Save role",
            icon: "pi pi-save",
            title: "Save role",
            command: () => {
              this.saveRoleFilter();
            },
          },
        ],
      });
    } else {
      this.moreConfigs.push({
        label: "Save user",
        icon: "pi pi-save",
        title: "Save user",
        command: () => {
          //this.saveUserFilter();
        },
      });
    }

    if (this.exportModeEnabled) {
      this.moreConfigs.push(
        ...[
          {
            label: "labelExport",
            icon: this.defaultActionsIcon,
            title: "labelExport",
            command: () => {
              this.exportModeActionExportClick();
            },
          },
        ]
      );
    } else {
      if (this.filterInit.export) {
        this.moreConfigs.push(
          ...[
            {
              label: "Export",
              icon: this.defaultActionsIcon,
              title: "Export",
              command: () => {
                this.defaultActionsClick();
              },
            },
          ]
        );
      }

      if (this.filterInit.actionList) {
        this.moreConfigs.push(
          ...[
            {
              label: "Export",
              icon: this.defaultActionsIcon,
              title: "Export",
              items: [
                {
                  label: "Default export",
                  icon: this.defaultActionsIcon,
                  title: "Default export",
                  command: () => {
                    this.defaultActionsClick();
                  },
                },
              ],
            },
          ]
        );

        this.actionsList?.forEach((element) => {
          this.moreConfigs
            .find((m) => m.label == "Export")
            ?.items?.push(element);
        });
      }

      if (!this.filterInit.autoloadData) {
        this.moreConfigs.push(
          ...[
            {
              label: "Submit",
              icon: "pi pi-search",
              title: "Submit",
              command: () => {
                this.searchData();
              },
            },
          ]
        );
      }
    }
  }


  protected async initFilters(notifyChange = true, loadfilterType = true) {
    if (this.isGlobalFilterView) {
      this.globals.user.currentGlobalFilters = this.selectedFilters;
    }

    for (const filter of this.filterTypeViewFilters) {
      if (
        filter.type !== "calendar" &&
        filter.type !== "input" &&
        filter.name !== "filterType"
      ) {
        //await this.initDropdownFilterDynamic(filter, false);
      }
      if (filter.type == "input") {
        this.selectedFilters[filter.name] = filter.defaultValue;
      }
    }

    // Reset Filters.
    if (
      !this.isGlobalFilterView &&
      (this.selectedFilters["filterType"] as IGeneralData).id == "global"
    ) {
      this.filterTypeViewFilters
        .filter((f) => f.name != "filterType")
        .forEach((f) => {
          const filterToUpdate =
            this.globals.user.currentGlobalFilters![f.name];
          if (filterToUpdate) {
            this.selectedFilters[f.name] = filterToUpdate;
          }
        });
    } else {
      this.initStartDateFilter(notifyChange);
      this.initEndDateFilter(notifyChange);

      // Fill wildcardFilterString input with saved data if exists
      if (
        this.filterTypeViewFilters.find((f) => f.name == "wildcardFilterString")
      ) {
        let defaultFilterWildCardFilter;

        if (this.isGlobalFilterView) {
          defaultFilterWildCardFilter = this.filterTypeViewFilters.find(
            (f) => f.name == "wildcardFilterString"
          )?.defaultValue;
        } else if (
          (this.selectedFilters["filterType"] as IGeneralData) && (this.selectedFilters["filterType"] as IGeneralData).id == GLOBAL
        ) {
          //defaultFilterWildCardFilter = this.globalFilters.filter(g => g.filter == "wildcardFilterString")[0]?.value;
        } else {
          //defaultFilterWildCardFilter = this.userSavedFilters.filter(u => u.filter == "wildcardFilterString")[0]?.value;
        }

        this.selected_wildcardFilterString =
          defaultFilterWildCardFilter ??
          this.filterTypeViewFilters.find(
            (f) => f.name == "wildcardFilterString"
          )?.defaultValue ??
          "0";
      }
    }
  }

  private setInfoViewInit() {
    /* if (this.infoView) {
      if (this.infoView.avalaiableViewModes && this.infoView.avalaiableViewModes.length) {
        if (this.filterTypeViewFilters.find(f=> f.name == "viewMode")) {
          this.filterTypeViewFilters.find(f=> f.name == "viewMode").visible = true;
          this.infoView.avalaiableViewModes.forEach((mode: string) => {
            //let translatedName = this.getTranslations('VIEWS.COMMONS.FILTERS.VIEWMODE.' + mode);
            //this.viewModeDropdown.push(new GeneralData(mode, translatedName));
            this.translate.get('VIEWS.COMMONS.FILTERS.VIEWMODE.' + mode).subscribe(translation => {
              this.viewModeDropdown.push(new GeneralData(mode, translation));
            })
          });

          let defaultViewMode = this.viewModeDropdown.find(m => m.id == this.infoView.defaultViewMode);
          if (defaultViewMode) {
            this.setViewMode(defaultViewMode, false);
          }
        }
        else {
          this.filters.viewMode = this.infoView.defaultViewMode;
        }
      }

      this.setDynamicFilters(this.infoView.dynamicFilters);
    } */
  }

  /* private setDynamicFilters(dynamicFilters: FilterInstance[]) {
    if (dynamicFilters) {
      dynamicFilters.forEach(instance => {
        Object.keys(this.filterInit.filter).forEach((keyInstance) => {
          const filterInstance = this.filterInit.filter[keyInstance] as FilterInstance;
          if (filterInstance.name == instance.name) {
            Object.keys(instance).forEach((filterProperty) => {
              if (instance[filterProperty] != null) {
                this.filterInit.filter[keyInstance]![filterProperty] = instance[filterProperty];
              }
            });
            return;
          }
        });
      });
    }
  } */

  public checkAllRequiredFilters(filters: Filters): boolean {
    let valid = false;

    if (this.filterTypeViewFilters) {
      valid = true;
      this.filterTypeViewFilters.forEach((filterInstance: FilterInstance) => {
        const filterKey = this.getFilterWildCardKey(filterInstance);
        const filter = filters[filterKey];

        if ((filter || this.isGlobalFilterView) && this.filterTypeViewFilters) {
        }

        if (filterInstance.required) {
          if (!filter || filter.length <= 0) {
            valid = false;

            // Check exists conditionals.
            if (
              filterInstance.conditionalsRequired &&
              filterInstance.conditionalsRequired.length
            ) {
              filterInstance.conditionalsRequired.forEach((key: any) => {
                const filter = filters[key];
                if (filter && filter.length) {
                  valid = true;
                  return;
                }
              });
            }

            if (!valid) {
              return;
            }
          }
        }
      });
    }

    this.validAllRequiredFilters.emit(valid);

    return valid;
  }

  protected getFilterWildCardKey(filterInstance: FilterInstance) {
    return filterInstance.visibleWildcard
      ? filterInstance.name + "WildCard"
      : filterInstance.name;
  }

  async filterTypeChange(event: any) {
    this.resetPageFilters();
    this.initStartDateFilter(false);
    this.initEndDateFilter(false);

    this.selectedFilters["filterType"] = this.filtersOptions["filterType"].find(
      (f) => f.id == event.id
    )!;

    await this.getAllViewFilters(event.id);

    const oldGlobalOpenedViewFilters = this.openedViewsFilters.filter(
      (v) => v.view == GLOBALFILTERS_VIEWNAME
    );
    oldGlobalOpenedViewFilters.forEach((filter: any) => {
      const index = this.openedViewsFilters.findIndex(
        (item: any) => JSON.stringify(item) === JSON.stringify(filter)
      );

      if (index !== -1) {
        this.openedViewsFilters.splice(index, 1);
      }
    });
    this.openedViewsFilters.push(
      ...this.filterTypeViewFilters.filter((f) => f.visible)
    );

    const uniqueItems = this.openedViewsFilters.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    );

    uniqueItems.forEach((filter: any) => {
      this.filterTypeViewFilters.find((f) => f.name == filter.name)!.visible =
        true;
    });

    await this.reloadData(false, false, true);

    if (this.isGlobalFilterView) {
      this.refreshService.refreshGlobalFilter(
        this.selectedFilters /* , this.filters */
      );
      // Load the same visible filters user had before changing template
      //this.showSelectedFilters(this.selected_visibleFilters);
    }
  }

  private notifyApplyFilters(filterName = null) {
    // This if is simply a trick to expect it to check first before data loading.
    if (this.checkNeedFilterDropdowns(filterName)) {
      this.checkNeedLoadingData();
    }
  }

  private checkNeedLoadingData() {
    if (
      this.filterInit.autoloadChangeFilter &&
      !this.filterInit.isLazyLoadData
    ) {
      this.loadingData(this.selectedFilters);
    }
  }

  protected checkNeedFilterDropdowns(filterName: any): boolean {
    if (this.filterTypeViewFilters) {
      if (
        this.checkFilterDrowpdown(
          this.filterTypeViewFilters.find((f) => f.name == filterName)!,
          filterName
        )
      ) {
        this.reloadDropdowns[filterName] = true;
        if (!this.filterInit.loadFilterWhenDropdown) {
          const filter = this.filterTypeViewFilters.find(
            (v) => v.name === filterName
          );
          this.initDropdownFilterDynamic(filter, false);
        }
      }

      if (
        this.checkFilterDrowpdown(
          this.filterTypeViewFilters.find((f) => f.name == "filterType")!,
          "filterType"
        )
      ) {
        this.reloadDropdowns["filterType"] = true;
        if (!this.filterInit.loadFilterWhenDropdown) {
          this.initTypeFilterDropdownFilter(false);
        }
      }
    }

    // Fix async with.
    return true;
  }

  resetDropdowns() {
    Object.keys(this.filtersOptions).forEach((key) => {
      if (key != "filterType") this.filtersOptions[key] = [];
    });
  }

  async reloadData(
    notifyChange = false,
    loadFilterType = true,
    cleanFilter = false
  ) {
    if (this.filterInit.cleanFilterOnReloadData || cleanFilter) {
      this.resetDropdowns();
      await this.initFilters(notifyChange, loadFilterType);
    }

    this.loadingData(this.selectedFilters);
  }

  searchData() {
    this.loadingData(this.selectedFilters);
  }

  loadingData(filters: Filters, isGlobal = false) {
    if (!this.isGlobalFilterView && !isGlobal) {
      this.globals.user.filters = filters;
    } else {
      this.globals.user.filters = { ...this.globals.user.filters, ...filters };
    }
    filters = this.globals.user.filters;
    this.setFiltersWildcard();
    if (this.checkAllRequiredFilters(filters)) {
      this.loading.emit(true);

      if (!this.isGlobalFilterView) {
        const adjustedFilters =
          this.generalDataService.getAdjustedFilters(filters);
        this.loadData.emit(adjustedFilters);
      }
    }
  }

  private showSelectedFilters(elements: IGeneralData[]) {
    // Hide all filters
    this.visibleFiltersDropdown.forEach((element) => {
      this.filterTypeViewFilters.find((f) => f.name == element.id)!.visible =
        false;
    });

    // Show selected filters
    elements.forEach((element) => {
      this.filterTypeViewFilters.find((f) => f.name == element.id)!.visible =
        true;
    });
  }

  //revisar con calma
  /* protected setGeneralDataFilters(elements: (IGeneralData[] | IGeneralData), name: string, stringToBool: boolean = false) {
    this.selectedFilters[name] = elements;
     let elementsQuery: any;
    if (elements) {
      if ((elements as IGeneralData).id === undefined) {
        elementsQuery = (elements as IGeneralData[]).map((s) => s.id);
      } else {
        elementsQuery = (elements as IGeneralData).id;
        if (stringToBool) {
          elementsQuery = Boolean(parseInt(elementsQuery));
        }
      }
    } else {
      elementsQuery = null;
    }

    this.changeFilter(elementsQuery, name);
  } */

  protected setViewMode(mode: IGeneralData, needReloadInfo: boolean = false) {
    this._selectedViewMode = mode;

    if (needReloadInfo) {
      this.resetDropdowns();
      this.loading.emit(true);
      //this.filters.viewMode = mode.id;
      /* this.generalDataService.changeViewMode(this.filters).subscribe(infoView => {
        // TODO Review it´s necesarry set other properties.
        this.setDynamicFilters(infoView.dynamicFilters);
        this.viewModeChange.emit(mode);
        this.resetViewMode();

        this.loadingData(this.filters);
      }, error => {
        this.throwError(error);
      }); */
    }
  }

  async resetViewMode() {
    /* var oldFilters = this.filters;

    Object.keys(this.filters).forEach((key) => {
      if (!this.filtersNotChangeMode.includes(key)) {
        //this.selectedFilters[key] = null;
        delete this.selectedFilters[key]
      }
    });

    await this.initFilters();
    this.filters = new Filters();
    this.filtersNotChangeMode.forEach((key) => {
      this.filters[key] = oldFilters[key];
    }); */
  }

  onDateSelect(value: any, name: any, elementToFilter: any = null) {
    if (this.isGlobalFilterView) {
      this.refreshService.refreshGlobalFilter(this.selectedFilters);
    } else {
      this.filterElement = elementToFilter;
      if (
        !this.filterTypeViewFilters.find((f) => f.name == "endDate")?.visible
      ) {
        //this.selectedFilters["endDate"] = this.setEndTime(new Date(value));

        // ChangeFilter skipApplyFilter to avoid send one event by component
        // Notify at end of change.
        this.notifyApplyFilters();
      }
    }
  }

  filterSelectionChange(value: any, name: any) {
    if (this.isGlobalFilterView) {
      this.refreshService.refreshGlobalFilter(this.selectedFilters);
    } else {
      this.notifyApplyFilters();
    }
  }

  changeDays(type: any) {
    const enabled =
      this.filterTypeViewFilters.find((f) => f.name == "startDate") ||
      this.filterTypeViewFilters.find((f) => f.name == "endDate");
    if (enabled) {
      let newFromDate = new Date();
      let newToDate = new Date();
      if (type == "previous") {
        if (this.filterTypeViewFilters.find((f) => f.name == "startDate")) {
          newFromDate = new Date(this.selectedFilters["startDate"] as Date);
          newFromDate.setDate(
            (this.selectedFilters["startDate"] as Date).getDate() -
            this.filterInit.dataRangeDays
          );
          this.selectedFilters["startDate"] = newFromDate;
        }

        if (this.filterTypeViewFilters.find((f) => f.name == "endDate")) {
          if (this.filterInit.dataRangeDays > 1) {
            newToDate = new Date(this.selectedFilters["startDate"] as Date);
            newToDate.setDate(
              (this.selectedFilters["startDate"] as Date).getDate() +
              this.filterInit.dataRangeDays
            );
          } else {
            newToDate = new Date(this.selectedFilters["endDate"] as Date);
            newToDate.setDate(
              (this.selectedFilters["endDate"] as Date).getDate() -
              this.filterInit.dataRangeDays
            );
          }

          this.selectedFilters["endDate"] = newToDate;
        } else {
          newToDate = new Date(this.selectedFilters["startDate"] as Date);
          newToDate.setDate(
            (this.selectedFilters["startDate"] as Date).getDate() + 1
          );
        }
      } else if (type == "next") {
        if (this.filterTypeViewFilters.find((f) => f.name == "startDate")) {
          newFromDate = new Date(this.selectedFilters["startDate"] as Date);
          newFromDate.setDate(
            (this.selectedFilters["startDate"] as Date).getDate() +
            this.filterInit.dataRangeDays
          );
          this.selectedFilters["startDate"] = newFromDate;
        }

        if (this.filterTypeViewFilters.find((f) => f.name == "endDate")) {
          newToDate = new Date(this.selectedFilters["endDate"] as Date);
          newToDate.setDate(
            (this.selectedFilters["endDate"] as Date).getDate() +
            this.filterInit.dataRangeDays
          );
          this.selectedFilters["endDate"] = newToDate;
        } else {
          newToDate = new Date(this.selectedFilters["startDate"] as Date);
          newToDate.setDate(
            (this.selectedFilters["startDate"] as Date).getDate() + 1
          );
        }
      }

      this.selectedFilters["startDate"] = newFromDate;
      this.selectedFilters["endDate"] = newToDate;

      if (this.isGlobalFilterView) {
        this.refreshService.refreshGlobalFilter(this.selectedFilters);
      }
      this.notifyApplyFilters();
    }
  }

  changeDay(type: any): boolean | undefined {
    const enabled =
      this.filterTypeViewFilters.find((f) => f.name == "startDate") ||
        this.filterTypeViewFilters.find((f) => f.name == "endDate")
        ? true
        : false;
    if (enabled) {
      // ChangeFilter skipApplyFilter to avoid send one event by component
      // Notify at end of change.
      this.notifyApplyFilters();
    }

    return enabled;
  }

  /* formatDate(date: any) {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    return date.getFullYear() + '-' + month + '-' + day;
  }   */

  protected loadInitView() {
    if (this.isFiltersInit && this.isViewInit) {
      const wildcardFilterStringFilter = this.filterTypeViewFilters.find(
        (f) => f.name == "wildcardFilterString"
      );
      if (
        wildcardFilterStringFilter &&
        wildcardFilterStringFilter.defaultValue != ""
      ) {
        this.selected_wildcardFilterString =
          wildcardFilterStringFilter.defaultValue;
      }
    }
  }

  checkFilterRequired(filter: FilterInstance) {
    return filter.required;
  }

  checkConditionalsRequired(filter: FilterInstance) {
    return filter.conditionalsRequired && filter.conditionalsRequired.length;
  }

  checkFilterDrowpdown(filter: FilterInstance, filterName: string) {
    return filter && filter.name != filterName;
  }

  checkFilterDefault(
    filter: FilterInstance,
    checkDefaultValue: boolean = true
  ) {
    return filter && (!checkDefaultValue || filter.defaultValue);
  }

  getFilterDefault(filter: FilterInstance, defaultIsEmpty: any = null) {
    return this.checkFilterDefault(filter)
      ? filter.defaultValue
      : defaultIsEmpty;
  }

  protected initStartDateFilter(
    notifyChange = true,
    newDate: Date = new Date()
  ): boolean | undefined {
    const enabled = this.filterTypeViewFilters.find(
      (f) => f.name == "startDate"
    )
      ? true
      : false;
    if (enabled) {
      const startDateFilter = this.filterTypeViewFilters.find(
        (f) => f.name == "startDate"
      )!;
      const filterDefault = this.checkFilterDefault(startDateFilter, false);
      let startDate = new Date();

      if (filterDefault) {
        if (startDateFilter.defaultValue) {
          if (startDateFilter.defaultValue instanceof Date) {
            startDate = startDateFilter.defaultValue;
          } else if (typeof startDateFilter.defaultValue == "number") {
            const currentDate = new Date();
            startDate = new Date(
              currentDate.setDate(
                currentDate.getDate() + startDateFilter.defaultValue
              )
            );
          } else if (typeof startDateFilter.defaultValue == "string") {
            startDate = new Date(startDateFilter.defaultValue);
          }
        }
        this.selectedFilters["startDate"] = startDate;
        const startTimeFilter = this.filterTypeViewFilters.find(
          (f) => f.name == "startTime"
        )!;
        if (this.checkFilterDefault(startTimeFilter, false)) {
          if (startTimeFilter.defaultValue) {
            this.selectedFilters["startDate"] = this.setDefaultTime(
              this.selectedFilters["startDate"] as Date,
              startTimeFilter.defaultValue
            );
          } else {
            (this.selectedFilters["startDate"] as Date).setHours(0, 0, 0, 0);
          }
        } else {
          (this.selectedFilters["startDate"] as Date).setHours(0, 0, 0, 0);
        }
      }
    }

    return enabled;
  }

  protected initEndDateFilter(
    notifyChange = true,
    newDate: Date = new Date()
  ): boolean | undefined {
    const enabled = this.filterTypeViewFilters.find((f) => f.name == "endDate")
      ? true
      : false;
    if (enabled) {
      const endDateFilter = this.filterTypeViewFilters.find(
        (f) => f.name == "endDate"
      )!;
      const filterDefault = this.checkFilterDefault(endDateFilter, false);
      let endDate = new Date();

      if (filterDefault) {
        if (endDateFilter.defaultValue) {
          if (endDateFilter.defaultValue instanceof Date) {
            endDate = endDateFilter.defaultValue;
          } else if (typeof endDateFilter.defaultValue == "number") {
            const currentDate = new Date();
            endDate = new Date(
              currentDate.setDate(
                currentDate.getDate() + endDateFilter.defaultValue
              )
            );
          } else if (typeof endDateFilter.defaultValue == "string") {
            endDate = new Date(endDateFilter.defaultValue);
          }
        }
        this.selectedFilters["endDate"] = this.setEndTime(endDate);
      }
    }

    return enabled;
  }

  // TODO Considered move to date filter utils.
  private setEndTime(date: Date): Date {
    const endTimeFilter = this.filterTypeViewFilters.find(
      (f) => f.name == "endTime"
    )!;
    if (this.checkFilterDefault(endTimeFilter, false)) {
      if (endTimeFilter.defaultValue) {
        date = this.setDefaultTime(date, endTimeFilter.defaultValue);
      } else {
        date.setHours(23, 59, 59, 0);
      }
    } else {
      date.setHours(23, 59, 59, 0);
    }

    return date;
  }

  // TODO Considered move to date filter utils.
  private setDefaultTime(date: Date, time: string): Date {
    // Now only parse hours and minutes: 21:10 per example.
    const timeParts = time.split(":");
    if (timeParts.length == 1) {
      date.setHours(parseInt(timeParts[0]));
    } else if (timeParts.length == 2) {
      date.setHours(parseInt(timeParts[0]), parseInt(timeParts[1]));
    }

    return date;
  }

  private capitalize(word: string) {
    return word[0].toUpperCase() + word.slice(1);
  }

  //TODO se convierte el observable en any debido al tipo de dato que devuelve la consulta de los procesos. Excepto esta consulta todos son GeneralData[]
  public initDropdownFilter(
    dropdown: any,
    filterInstance: FilterInstance,
    callback: string
  ): Observable<IGeneralData[]> | null {
    const filterKey = filterInstance.name;
    const reload = this.reloadDropdowns[filterKey] === true;

    //if (dropdown.length > 0 && !reload) return null;

    //this.reloadDropdowns[filterKey] = false;

    //this.loadingFilters.emit(true);
    this.isFiltersLoading = true;
    //this.spinner.show();
    console.log("callback", callback);
    return this.generalDataService[callback](this.selectedFilters);
  }

  public processDropdownResults(
    filterInstance: FilterInstance,
    setDefaultExists: boolean,
    result: IGeneralData[]
  ) {
    //this.loading.emit(false);
    this.isFiltersLoading = false;
    this.spinner.hide();

    const filterKey = filterInstance.name;
    let filterDefaultValue =
      this.selectedFilters[filterInstance.name] ??
      this.getFilterDefault(filterInstance);
    if (
      setDefaultExists &&
      filterDefaultValue &&
      (!this.selectedFilters[filterKey] ||
        (this.selectedFilters[filterKey] as Array<IGeneralData>).length == 0)
    ) {
      const isDefaultArray = filterDefaultValue instanceof Array;

      if (
        filterInstance.name != "withinWorkingHours" &&
        filterInstance.name != "isPercentage"
      ) {
        // Change boolean values retrieved from database to match the format used in IGeneralData elements if they are different
        if (isDefaultArray) {
          (filterDefaultValue as IGeneralData[]).forEach(
            (val: any, index: any) => {
              val = val == "False" ? "0" : val == "True" ? "1" : val;
              (filterDefaultValue as IGeneralData[])[index] = val;
            }
          );
        } else {
          filterDefaultValue =
            filterDefaultValue == "False"
              ? "0"
              : filterDefaultValue == "True"
                ? "1"
                : filterDefaultValue;
        }
      }

      if (filterInstance.type == "multiSelect") {
        if (isDefaultArray) {
          this.selectedFilters[filterKey] = result.filter((r) =>
            (filterDefaultValue as IGeneralData[]).find(
              (key: any) =>
                key.toString().toLowerCase() == r.id?.toString().toLowerCase()
            )
          );
        } else {
          this.selectedFilters[filterKey] = result.filter(
            (r) =>
              r.id?.toString().toLowerCase() ==
              filterDefaultValue.toString().toLowerCase()
          );
        }
      } else {
        if (!isDefaultArray) {
          this.selectedFilters[filterKey] = result.find(
            (r) =>
              r.id?.toString().toLowerCase() ==
              filterDefaultValue.toString().toLowerCase()
          )!;
        } else {
          this.selectedFilters[filterKey] = result.find(
            (r) =>
              r.id?.toString().toLowerCase() ==
              (filterDefaultValue as IGeneralData[])[0].toString().toLowerCase()
          )!;

          if ((filterDefaultValue as IGeneralData[]).length > 1) {
            console.error(
              "You cannot define more than one default value in a dropdown that is not multiple"
            );
          }
        }
      }
    } else if (!result.length) {
      this.selectedFilters[filterKey] = [];
      //  this.changeFilter(null, filterKey, true);
    }
  }

  protected processErrorDrowdowns(error: any) {
    this.loading.emit(false);
  }


  public async initDropdownFilterDynamic(
    filter: any,
    setDefaultExists: boolean = true
  ) {
    // console.log("filter-> ", filter);
    const filterInstance = this.filterTypeViewFilters.find(
      (f) => f.name == filter.name
    )!;
    this.filtersOptions[filter.name] = [];
    if (!this.filtersOptions[filter.name])
      this.filtersOptions[filter.name] = [];

    const subscriber = this.initDropdownFilter(
      this.filtersOptions[filter.name],
      filterInstance,
      "get_" + filter.endpointName
    );
    if (subscriber) {
      return new Promise((resolve, reject) => {
        subscriber.subscribe({
          next: (result) => {
            result = this.addAllSelectedItems(filterInstance, result);
            this.filtersOptions[filter.name] = result;

            this.filtersOptions[filter.name].forEach((x: any) => {
              x.translatedName = this.translate.get(
                "FILTERS." + filter.name.toUpperCase() + ".OPTIONS." + x.name
              );
            });

            this.processDropdownResults(
              filterInstance,
              setDefaultExists,
              result
            );

            const filterDefault = this.filterTypeViewFilters.find(
              (f) => f.name == filter.name
            )!.defaultValue;
            const defaultValues = isString(filterDefault)
              ? filterDefault.split(this.stringToArraySeparator)
              : filterDefault;
            if (defaultValues && filter.name != "filterType") {
              let selectedOptions = this.filtersOptions[filter.name].filter(
                (f: any) => defaultValues.includes(f.id)
              ) as any;

              if (Array.isArray(selectedOptions) && filter.type == "dropdown")
                selectedOptions = selectedOptions[0];
              this.selectedFilters[filter.name] = selectedOptions;
            }

            resolve(result);
          },
          error: (error) => {
            this.processErrorDrowdowns(error);
            reject(error);
          }
        });
      });
    }
    return of();
  }

  public async initTypeFilterDropdownFilter(setDefaultExists: boolean = true) {
    return new Promise(async (resolve, reject) => {
      (
        await this.generalDataService.get_filterTypes(this.currentViewName)
      )?.subscribe(
        (result) => {
          this.filtersOptions["filterType"] = result;

          if (this.isGlobalFilterView) {
            this.filtersOptions["filterType"] = this.filtersOptions[
              "filterType"
            ].filter((f) => f.id != GLOBAL);
          }

          // Add option in dropdown for unsaved filter configurations
          if (this.unsavedFilter) {
            this.filtersOptions["filterType"].push(this.unsavedFilterType);
          }

          const filterDefaultValue = this.getFilterDefault(
            this.filterTypeViewFilters.find((f) => f.name == "filterType")!
          );
          if (
            setDefaultExists &&
            filterDefaultValue &&
            !this.selectedFilters["filterType"]
          ) {
            this.selectedFilters["filterType"] = result.find(
              (r) => r.id == filterDefaultValue
            )!;
          }
          resolve(result);
        },
        (error) => {
          this.processErrorDrowdowns(error);
          reject(error);
        }
      );
    });
  }

  private addAllSelectedItems(
    filterInstance: FilterInstance,
    result: IGeneralData[]
  ): IGeneralData[] {
    const dropDownItems: any[] = []; // New IGeneralData entries for dropDown created for selected values in the filter
    let selectedValues = this.selectedFilters[filterInstance.name]; // All values applied in present filter

    // If this filter was not used before, is undefined
    if (selectedValues) {
      if (!Array.isArray(selectedValues)) {
        selectedValues = [selectedValues as IGeneralData];
      }

      // Create IGeneral items for each selected value. If values are booleans, they will be converted
      // for using same ids like IGeneral items used in result
      selectedValues.forEach((s: any) => {
        dropDownItems.push({
          id: s == "False" ? "0" : s == "True" ? "1" : s,
          name: s,
          hasNoData: false,
        });
      });

      // Find values for filter that have no data in the current view (they are selected but not present in result)
      const selectedWithoutData = dropDownItems.filter(
        (s) => result.findIndex((r) => r.id == s.id) == -1
      );
      for (const s of selectedWithoutData) {
        s.hasNoData = true;
      }
      // Add the new selected entries to result. They will appear at the beginning
      result = selectedWithoutData.concat(result);

      // Apply same properties to selected entries stored in selectedFilters. This avoids duplicates if hasNoData value is different between result and selectedFilters

      if (filterInstance.name == "withinWorkingHours") {
        //TODO: check this conversion
        //this.selectedFilters[filterInstance.name] = result.filter(r => r.id.toString().toLowerCase() == "true" );
      }
      this.selectedFilters[filterInstance.name] = result.filter((r) =>
        (selectedValues as string).includes(r.id)
      );
    }

    return result;
  }

  // Fill General Filters Multiselect using filtersInstance properties except some of them
  // Dropdowns use GeneralData objects. We create them in the map method providing a name and an id (they are the same for this purpose)
  public async initVisibleFiltersDropdown() {
    const excludedProperties = [
      "filterType",
      "startTime",
      "endTime",
      "numbers",
      "viewMode",
    ];
    const filtersList = this.filterTypeViewFilters.filter(
      (filterName) => !excludedProperties.includes(filterName.name)
    );

    this.visibleFiltersDropdown = filtersList.map((item: any) => ({
      id: item.name,
      name: this.translate.get("FILTERS." + item.name.toUpperCase() + ".TITLE"),
    }));
  }

  // This method updates the list of selected global filters
  // It's used when loading the view the first time because we have default filters selected
  updateSelectedVisibleFilters() {
    if (this.isGlobalFilterView) {
      this._selectedVisibleFilters = [];

      // Fill the list with the visible filters checking their status in filterInstance object
      this.visibleFiltersDropdown.forEach((filter: any) => {
        if (
          this.filterTypeViewFilters.find((f) => f.name == filter.id) &&
          this.filterTypeViewFilters.find((f) => f.name == filter.id)!.visible
        ) {
          this._selectedVisibleFilters.push(filter);
          this.filterTypeViewFilters.find((f) => f.name == filter.id);
        }
      });
    }
  }

  public initColumns(columns: Column[]) {
    columns.forEach((column) => {
      // If not exists properties considerd that can be displayed and selected
      // This indicated that by default all colmns can be selectable and display
      // to select if not indicated "false"
      if (column.selectable === false && column.selected !== false) {
        this._selectedColumns.push(column);
      } else if (
        column.selectable !== false &&
        !this.uniqueColumns.find((c) => c.header == column.header)
      ) {
        this.uniqueColumns.push(column);
        if (column.selected !== false) {
          this._selectedColumns.push(column);
        }
      }
    });
  }

  saveRoleFilter() {
    const data: any[] = [];
    this.roleService.getRoles()?.subscribe((res) => {
      res.forEach((element) => {
        data.push({ id: element.id, name: element.name });
      });
      // TODO Translate.
      this.ref = this.dialogService.open(FilterDefaultDialog, {
        header: "Selección de Roles",
        baseZIndex: 10000,
        data: data,
      });
      this.ref.onClose?.subscribe((rol: any) => {
        if (rol) {
          this.generalDataService
            .setDefaultFiltersByRole(
              this.selectedFilters /* this.filters */,
              this.currentViewName,
              rol.id
            )
            ?.subscribe(async (result) => {
              //this.userSavedFilters = await this.getSavedFilters();
            });
          // TODO Translate.
          this.toastrService.success(
            "Filtros por defecto",
            "Filtros Guardados",
            { timeOut: 3000 }
          );
        }
      });
    });
  }

  /* saveUserFilter() {
    this.filters = this.filters || new Filters();
    let selectedFilterType = this.selectedFilterTypeString ? this.selectedFilterTypeString : "user";
    if (this.isGlobalFilterView && selectedFilterType == GLOBAL){ //this.currentViewName == GLOBALFILTERS_VIEWNAME
      selectedFilterType = "default";
    }
    if (!this.isGlobalFilterView){ //this.currentViewName != GLOBALFILTERS_VIEWNAME
      selectedFilterType = "user";
    }      
    this.filters[FILTERTYPE_NAME] = selectedFilterType;

    this.selectedFilterTypeString = selectedFilterType;


    this.generalDataService.setDefaultFiltersByUser(this.filters, this.currentViewName, this.globals.user.id).subscribe(async result => {
      //this.userSavedFilters = await this.getSavedFilters();

      // Recover new options for TypeFilterDropDownFilter
      await this.initTypeFilterDropdownFilter(false);
      //this.selected_filterType = { id: "user", name: "User" };
      this.selectedFilters["filterType"] = this.filtersOptions['filterType'].find(f=> f.id == "default")!;
    });
  } */




  resetPageFilter(filterInstance: FilterInstance, skipApply: boolean = true) {
    const filterKey = this.getFilterWildCardKey(filterInstance);
    this.selectedFilters[filterKey] = [];
  }

  resetPageFilters() {
    if (this.selectedFilters) {
      Object.keys(this.selectedFilters).forEach((key) => {
        delete this.selectedFilters[key];
      });
    }

    this.selectedFilters = {};
  }

  resetFilters(resetFilterType = true) {
    this.resetPageFilters();

    // TODO Translate.
    /* this.generalDataService.deleteDefaultFilter(this.currentViewName).subscribe(w => {
      this.toastrService.success('Filtros por defecto', 'Filtros Eliminados', { timeOut: 3000 });
      this.loadingData(this.filters);
    }, error => {
      this.throwError(error);
    }); */

    if (resetFilterType) {
      this.selectedFilters["filterType"] = {
        id: GLOBAL,
        name: this.capitalize(GLOBAL),
      };
      this.filterTypeViewFilters.find(
        (f) => f.name == "filterType"
      )!.defaultValue = null;
    }

    if (this.isGlobalFilterView)
      this.refreshService.refreshGlobalFilter(this.selectedFilters);
  }

  protected setFiltersWildcard() {
    //this.filters.processNamesWildCard = this.filterWildCardProcess;
    //this.filters.hostNamesWildCard = this.filterWildCardHost;
  }

  setfilterWildcardString(value: string) {
    //this.selected_wildCardFilterString = value;
    //this.filters.wildcardFilterString = this.selected_wildcardFilterString;
  }

  defaultActionsClick() {
    this.exportOnClick.emit();
  }

  exportModeChanged() {
    this.exportModeChange.emit(this.exportModeEnabled);
  }

  exportModeFilter() {
    this.searchData();
  }

  exportModeActionExportClick() {
    this.setFiltersWildcard();
    if (this.checkAllRequiredFilters(this.selectedFilters)) {
      this.exportModeExportOnClick.emit();
    }
  }

  getRequiredMark(filter: FilterInstance) {
    return this.checkFilterRequired(filter)
      ? !this.checkConditionalsRequired(filter)
        ? this.defaultRequiredMark
        : this.defaultConditionalRequiredMark
      : "";
  }

  throwError(error: any) {
    //TODO consider to show generic error message
    this.spinner.hide();
  }

  private isAdmin() {
    return (
      this.accountService.currentUser?.isAdmin ||
      this.accountService.currentUser?.isGlobalAdmin ||
      this.accountService.currentUserRoles
        .map((x) => x.name?.toLowerCase())
        .includes("admin")
    );
  }

  /* async getSavedFilters(): Promise<any[]> {
    //var userId = this.globals.user.id;
    var userId = "F6A555E5-F08F-42AD-B566-08D87F1CD212";
    //var userRolesId = this.globals.user.isGlobalAdmin ? "085DAE0A-01E6-4CF7-88FE-08D87F1CD1AD" : this.globals.user.roles[0].id;
    var userRolesId = "085DAE0A-01E6-4CF7-88FE-08D87F1CD1AD";
    var defaultfilters: any[] = [];

    const result = await this.generalDataService.getSavedFilters(userRolesId, userId).toPromise();

    result?.forEach((element) => {
      defaultfilters.push({
        filter: element.nameFilter,
        value: element.value,
        view: element.view,
        type: element.rolId ? "rol" : element.userId ? "user" : null,
      });
    });

    if (defaultfilters.filter(x => x.view == this.currentViewName).length == 0) {
      //defaultfilters.push({ filter: FILTERTYPE_NAME, value: GLOBAL, view: this.currentViewName, type: null });
      defaultfilters.push({ filter: FILTERTYPE_NAME, value: GLOBAL, view: this.currentViewName, type: GLOBAL });
    }

    return defaultfilters;
  } */

  // Open a dialog for selecting items
  hideTooltip(event?: any) {
    this.openDialogs++;
    this.tooltipIsHidden = true;
  }

  // Close one dialog
  showTooltip(event?: any) {
    this.openDialogs--;
    this.openDialogs == 0
      ? (this.tooltipIsHidden = false)
      : (this.tooltipIsHidden = true);
    this.visibleFiltersDropdown = [];
  }
}
