import { Component, ElementRef, Renderer2, ViewChild, AfterViewInit } from "@angular/core";

@Component({
  selector: "app-signal-dashboard",
  templateUrl: "./signalDashboard.component.html",
  styleUrls: ["./signalDashboard.component.css"],
})
export class SignalDashboardComponent implements AfterViewInit {
  @ViewChild('iframeContainer', { static: false }) container!: ElementRef;

  variables: any[] = []; // Variables dinámicas cargadas desde el iframe
  iframe!: HTMLIFrameElement;
  maxRetries: number = 10; // Número máximo de intentos para cargar variables
  retryInterval: number = 2000; // Intervalo entre reintentos en milisegundos

  constructor(private renderer: Renderer2) { }

  ngAfterViewInit() {
    // Crear y configurar iframe
    this.iframe = this.renderer.createElement('iframe');
    this.renderer.setAttribute(
      this.iframe,
      'src',
      "https://activity-oa3.develop.overa.net/dashboards/d/eb2940e1-e310-4031-a43d-7b2c95cbea66/server-ux?orgId=1&refresh=1m"
    );
    this.renderer.setStyle(this.iframe, 'width', '100%');
    this.renderer.setStyle(this.iframe, 'height', '600px');
    this.renderer.appendChild(this.container.nativeElement, this.iframe);

    // Esperar a que el iframe cargue completamente
    this.iframe.onload = () => {
      this.loadVariablesFromIframe();
    };
  }

  private loadVariablesFromIframe() {
    const iframeWindow = this.iframe.contentWindow;
    const iframeDocument = iframeWindow?.document;

    if (!iframeDocument) {
      console.error('No se pudo acceder al documento del iframe.');
      return;
    }

    const retryLoad = (retries: number) => {
      if (retries <= 0) {
        console.error('No se pudieron cargar las variables desde el iframe después de varios intentos.');
        return;
      }

      try {
        const variableElements = iframeDocument.querySelectorAll(".variable-link-wrapper");

        if (variableElements.length === 0) {
          console.log('Variables aún no disponibles, reintentando...');
          setTimeout(() => retryLoad(retries - 1), 500); // Reintenta después de 500 ms
          return;
        }

        const variablesMap = new Map();

        for (const v of Array.from(variableElements)) {
          const reactPropsKey = Object.keys(v).find(s => s.startsWith("__reactProps"));
          const reactProps = reactPropsKey ? (v as any)[reactPropsKey] : undefined;
          const variableObject = reactProps.children._owner.memoizedProps.variable;

          const variableName = variableObject.name;
          const options = variableObject.options;
          const multi = variableObject.multi;

          const parsedOptions = options.map((o: any) => ({
            value: o.value,
            text: o.text,
            selected: o.selected,
          }));

          variablesMap.set(variableName, { multi, options: parsedOptions });
        }

        const variablesObject = Array.from(variablesMap, ([name, values]) => ({
          name,
          multi: values.multi,
          options: values.options,
        }));

        console.log('Variables obtenidas del iframe:', variablesObject);
        this.variables = variablesObject.map((v: any) => ({
          name: v.name,
          multi: v.multi,
          options: v.options,
          selectedValues: v.multi
            ? v.options.filter((option: any) => option.selected).map((option: any) => option.value)
            : null,
          selectedValue: !v.multi
            ? v.options.find((option: any) => option.selected)?.value
            : null,
        }));


      } catch (error) {
        console.error('Error al cargar variables desde el iframe:', error);
      }
    };

    retryLoad(10); // Máximo de 10 reintentos
  }
  applyFilters() {
    if (!this.iframe || !this.iframe.src) {
      console.error('El iframe o su URL no están definidos.');
      return;
    }

    try {
      const baseUrl = this.iframe.src.split('?')[0]; // Obtener la URL base (sin parámetros)
      const params = new URLSearchParams();

      // Construir los parámetros según los valores seleccionados
      this.variables.forEach(variable => {
        if (variable.multi) {
          // Añadir múltiples valores para el mismo parámetro
          variable.selectedValues?.forEach((value: string) => {
            params.append(`var-${variable.name}`, value);
          });
        } else {
          // Añadir un solo valor para el parámetro
          if (variable.selectedValue) {
            params.append(`var-${variable.name}`, variable.selectedValue);
          }
        }
      });

      // Generar la nueva URL combinando la base con los parámetros
      const newUrl = `${baseUrl}?${params.toString()}`;
      console.log('Nueva URL generada:', newUrl);

      // Actualizar el iframe con la nueva URL
      this.renderer.setAttribute(this.iframe, 'src', newUrl);
    } catch (error) {
      console.error('Error al actualizar la URL del iframe:', error);
    }
  }
}
