import {
    Injectable,
} from "@angular/core";

import { BaseService, ConfigsService, PaginatedResponse } from "@overa/shared";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ActivityGoldenLayout } from "../models/activity-golden-layout.model";

@Injectable({
    providedIn: "root",
})
export class ActivityGoldenLayoutService extends BaseService<ActivityGoldenLayout> {
    override get apiBaseUrl(): string {
        return this.config.getModuleConfig("overa-security", "apiBaseUrl") + "/api";
    }

    override get path(): string {
        return "/goldenLayout";
    }

    private get goldenLayoutApiUrl(): string {
        return this.apiBaseUrl + this.path;
    }

    constructor(
        public override http: HttpClient,
        protected override config: ConfigsService
    ) {
        super(http, config);
    }

    getUserLayouts(userid: string): Observable<PaginatedResponse<ActivityGoldenLayout>> {
        return this.http.get<PaginatedResponse<ActivityGoldenLayout>>(`${this.goldenLayoutApiUrl}/getUserLayouts/`, {
            params: { userId: userid },
        });
    }

    saveUserLayout(layout: ActivityGoldenLayout): Observable<ActivityGoldenLayout> {
        return this.http.post<ActivityGoldenLayout>(`${this.goldenLayoutApiUrl}/saveUserLayout/`, layout);
    }

}
