import "@webcomponents/custom-elements";
import "@angular/localize/init";

// import { bootstrapApplication } from '@angular/platform-browser';
// import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
// import { provideHttpClient, withFetch } from '@angular/common/http';

import { enableProdMode } from "@angular/core";
// import { ApplicationConfig } from '@angular/core';
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

// import Aura from '@primeng/themes/aura';
// import { providePrimeNG } from 'primeng/config';

// export const appConfig: ApplicationConfig = {
//   providers: [
//     provideHttpClient(withFetch()),
//     provideAnimationsAsync(),
//     providePrimeNG({
//       theme: { preset: Aura },
//     }),
//   ],
// };

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = () => {
      // Disable console.log in production
    };
  }
}

// bootstrapApplication(ActivityAppComponent, appConfig).catch((err) =>
//   console.error("MAIN.TS - Error: ", err instanceof Error ? err.message : err)
// );

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((error) => {
    console.error("MAIN.TS - Error: ", error instanceof Error ? error.message : error);
  });
