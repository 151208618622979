
import { ReportingService } from "../services/reporting.service";
import {
  MessageTemplateService,
  NotificationService,
} from "@overa/notification";
import { ActivityGlobals } from "./globals";
import { StorageService } from "@overa/storage";
import { InjectionToken, Type } from "@angular/core";
import { IdentityService, ObservableService } from "@overa/shared";
import { DepartmentService } from "../services/department.service";
import { ComponentsService } from "@overa/dynamic/components";

export const dynamic_registry: {
  [key: string]: Type<any> | InjectionToken<any>;
} = {
  Globals: ActivityGlobals,
  IdentityService: IdentityService,
  ReportingService: ReportingService,
  MessageTemplateService: MessageTemplateService,
  NotificationService: NotificationService,
  StorageService: StorageService,
  DepartmentService: DepartmentService,
  ComponentsService: ComponentsService,
  ObservableService: ObservableService,
};
