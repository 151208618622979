<div>
    <div *ngIf="useDynamicFilters">
        <div class="filters-container">

            <!-- Sección para el rango de tiempo -->
            <div class="time-range-container">
                <label>Rango de tiempo</label>
                <div style="display: flex; align-items: center; gap: 10px;">
                    <!-- Input de fecha de inicio -->
                    <p-calendar [(ngModel)]="timeRange.from" [showTime]="true" placeholder="Desde"></p-calendar>

                    <!-- Input de fecha de fin -->
                    <p-calendar [(ngModel)]="timeRange.to" [showTime]="true" placeholder="Hasta"></p-calendar>

                    <!-- Selector de intervalos rápidos -->
                    <p-dropdown [options]="quickRanges" [(ngModel)]="selectedQuickRange" (onChange)="applyQuickRange()"
                        placeholder="Rangos rápidos"></p-dropdown>
                </div>
                <button pButton type="button" label="Aplicar Rango de Tiempo" (click)="applyTimeRange()"></button>
            </div>

            <!-- Sección para las variables dinámicas -->
            <div *ngFor="let variable of variables" class="filter-item">
                <label>{{ variable.name }}</label>
                <p-multiSelect *ngIf="variable.multi" [options]="variable.options" [(ngModel)]="variable.selectedValues"
                    optionLabel="text">
                </p-multiSelect>
                <p-dropdown *ngIf="!variable.multi" [options]="variable.options" [(ngModel)]="variable.selectedValue"
                    optionLabel="text">
                </p-dropdown>
            </div>

            <!-- Sección para filtros Ad-Hoc -->
            <div *ngFor="let filter of adhocFilters; let i = index" class="filter-item">
                <div style="display: flex; align-items: center; margin-bottom: 10px;">
                    <!-- Clave del filtro -->
                    <label style="margin-right: 10px;">{{ filter.key }}</label>

                    <!-- Operador del filtro -->
                    <p-dropdown [options]="filter.operators" [(ngModel)]="filter.selectedOperator" optionLabel="value"
                        optionValue="value">
                    </p-dropdown>
                    <!-- Valor del filtro -->
                    <input type="text" [(ngModel)]="filter.selectedValue" placeholder="Ingrese un valor" />

                    <!-- Botón para eliminar el filtro -->
                    <button pButton type="button" label="Eliminar" icon="pi pi-trash" class="p-button-danger"
                        (click)="removeFilter(i)">
                    </button>
                </div>
            </div>
            <button pButton type="button" label="Aplicar Filtros" (click)="applyFilters()"></button>
        </div>
    </div>
    <div class="iframeContainer" #iframeContainer></div>
</div>