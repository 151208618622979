
<div class="noGoldenLayout-2 d-flex justify-content-center align-items-center master_container" >
  
  <div class="container">

   <!-- EXAMPLE ICON BUTTONS-->
    <div class="row">

      <div class="col-xxl-6 col-xl-6 col-lg-6 col-12 text-center">
        <h3>TI</h3>
        <div class="container-icon-home">
          <a title="TI" href=""> <i class="material-icons icon-home">pie_chart</i> </a>
        </div>
      </div>
  
      <div class="col-xxl-6 col-xl-6 col-lg-6 col-12 text-center">
        <h3>RRHH</h3>
        <div class="container-icon-home">
          <a title="RRHH" href=""> <i class="material-icons icon-home">supervised_user_circle</i> </a>
        </div>
      
      </div>
    </div>
   <!-- END EXAMPLE ICON BUTTONS-->


    <!-- EXAMPLE IMAGE BUTTONS SVG -->
    <div class="clear"></div>

    <div class="row">
     
      <div class="col-xxl-6 col-xl-6 col-lg-6 col-12 text-center">
       <h3>TI</h3>
         <a title="TI" href=""><img class="img-home" src="assets/img/home/TI.svg" alt="TI" /></a>
     </div>

     <div class="col-xxl-6 col-xl-6 col-lg-6 col-12 text-center">
       <h3>RRHH</h3>
       <a title="RRHH" href=""><img class="img-home" src="assets/img/home/RRHH.svg" alt="RRHH" /></a>
     </div>

   </div>
    <!-- END EXAMPLE IMAGE BUTTONS SVG -->


 </div>

</div> 