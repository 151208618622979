<div class="iframeContainer" #iframeContainer></div>
<div *ngFor="let variable of variables">
    <label>{{ variable.name }}</label>
    <p-multiSelect *ngIf="variable.multi" [options]="variable.options" [(ngModel)]="variable.selectedValues"
        optionLabel="text">
    </p-multiSelect>
    <p-dropdown *ngIf="!variable.multi" [options]="variable.options" [(ngModel)]="variable.selectedValue"
        optionLabel="text">
    </p-dropdown>
</div>

<!-- Botón para aplicar los filtros -->
<button pButton type="button" label="Aplicar Filtros" (click)="applyFilters()"></button>"