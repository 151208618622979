import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivityBaseTableComponent } from "../../shared/activity-base-table-component.component";
import { Filters } from "src/app/models/filters.model";
import { COLUMN_DATETIME_LONG_PIPE } from "src/app/shared/pipes/column_pipes/column-data-formatter.pipe";
import { ControlCommand } from "src/app/models/controlCommand.model";
import { ControlCommandService } from "src/app/services/controlCommand.service";
import { DatePipe } from "@angular/common";
import { OveraCommandConsoleComponent } from "../../shared/overa-command-console/overa-command-console.component";
import { OveraDialogRef, OveraDialogService } from "@overa/components/dialog";
import {
  InfoDialogComponent,
  OveraMessagesService,
} from "@overa/components/info-dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { OveraDataTableColumn, TableAction } from "@overa/components/common";

@Component({
  selector: "app-device-commands",
  templateUrl: "./device-commands.component.html",
  styleUrls: ["./device-commands.component.scss"],
  providers: [DatePipe, OveraDialogService],
})
export class DeviceCommandsComponent
  extends ActivityBaseTableComponent<ControlCommand>
  implements OnInit {
  @ViewChild("statusBarTemplate", { static: true })
  statusBarTemplate!: TemplateRef<any>;

  commandDialog?: OveraDialogRef;
  acceptDialogRef?: MatDialogRef<InfoDialogComponent>;

  secondaryColumns: OveraDataTableColumn[] = [];

  data: ControlCommand[] = [];
  itemsTemp: ControlCommand[] = [];
  totalRecords: number = 20;

  datatableLiterals: { [literal: string]: string } = {
    NoData: $localize`:@@common.nodata: No hay datos para mostrar`,
    Yes: $localize`:@@common.yes: Yes`,
    No: $localize`:@@common.no: No`,
    FilterBy: $localize`:@@datatable.filterby: Filtrar por`,
    checkboxOff: $localize`:@@common.no: No`,
    checkboxOn: $localize`:@@common.yes: Sí`,
  };

  tableActions: TableAction[] = [];

  constructor(
    protected controlCommandService: ControlCommandService,
    protected dialogService: OveraDialogService,
    protected overaMessagesService: OveraMessagesService
  ) {
    super();
  }

  override ngOnInit(): void {
    this.createColumns();
    this.tableActions = [
      { id: "new", icon: "pi pi-plus-circle" },
      { id: "delete", icon: "pi pi-trash" },
    ];
  }

  override loadData(filters: Filters): void {
    // Set endDate to same day of startDate
    filters["endDate"] = new Date(filters["startDate"]);
    filters["endDate"].setHours(23, 59, 59);

    this.loading = true;

    this.controlCommandService.getControlCommands(filters).subscribe({
      next: (res) => {
        this.data = res;
        this.itemsTemp = res;
      },
      complete: () => (this.loading = false),
    });
    // TODO: Método de prueba que carga los deliveries. Hay que hacer que esto se llame solo para la fila que se expande
    this.controlCommandService.getControlCommandDeliveries().subscribe({
      next: (res) => {
        console.log("Deliveries: ", res);
        this.data.forEach(
          (command) => (command.controlCommandDeliveries = res)
        );
      },
      complete: () => (this.loading = false),
    });
  }

  loadDataFunction = (
    pag: number,
    items_per_pag: number,
    order: { field: string; order: number }[]
  ): any[] => {
    if (pag != undefined && pag != null) {
      pag = pag * items_per_pag;

      const startIndex = pag;
      const endIndex = Math.min(pag + items_per_pag, this.itemsTemp.length);
      const loadItems = this.itemsTemp.slice(startIndex, endIndex);
      this.data = loadItems;
      if (this.dataTable) this.dataTable.items = loadItems;
      return loadItems;
    } else {
      return [];
    }
  };

  // TODO: Aplicar traducciones en los headers en lugar de strings harcodeados
  createColumns() {
    this.columns = [
      {
        field: "description",
        header: "Tarea",
        type: "String",
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "commandType",
        header: "Comando",
        type: "String",
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "createdDate",
        header: "Fecha",
        type: "DateTime",
        pipes: COLUMN_DATETIME_LONG_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "completedPercentage",
        header: "Progreso",
        type: "Template",
        responsive: true,
        responsiveHeader: true,
        template: this.statusBarTemplate,
      },
      {
        field: "queuedCount",
        header: "Pendiente",
        type: "String",
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "completedCount",
        header: "OK",
        type: "String",
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "failedCount",
        header: "Error",
        type: "String",
        responsive: true,
        responsiveHeader: true,
      },
    ];
    this.secondaryColumns = [
      {
        field: "hostname",
        header: "Host",
        type: "String",
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "status",
        header: "Estado",
        type: "String",
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "createdDate",
        header: "Fecha",
        type: "DateTime",
        pipes: COLUMN_DATETIME_LONG_PIPE,
        responsive: true,
        responsiveHeader: true,
      },
      {
        field: "lastResult",
        header: "Respuesta",
        type: "String",
        responsive: true,
        responsiveHeader: true,
        cssClass: "lastResult",
      },
    ];
  }

  getProgressPercentage(progress: string): number {
    let numbers = progress.split("/");
    let percentage = numbers[0] != "0" ? (+numbers[0] / +numbers[1]) * 100 : 0;
    return percentage;
  }

  // TODO: Usar traducciones
  openCommandConsole(data?: any) {
    this.commandDialog = this.dialogService.open(
      OveraCommandConsoleComponent,
      {},
      {
        header: "Nueva tarea",
        width: "50vw",
        styleClass: "no-header-title",
        contentStyle: {
          "min-height": "70vh",
          "max-height": "80vh",
          overflow: "auto",
          baseZIndex: 10000,
          data: data,
        },
      }
    );
  }

  onTableAction(action: TableAction) {
    if (action.id == "delete") {
      this.acceptDialogRef = this.overaMessagesService.showDialog(
        "Diálogo",
        "Texto",
        "buttons"
      );
      this.acceptDialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.deleteItems();
        }
      });
      return;
    }
    if (action.id == "new") {
      this.openCommandConsole();
    }
  }

  deleteItems() {
    console.log("Ejecutado borrado!!");
  }
}
