import {
  ApplicationRef,
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  ComponentContainer,
  ComponentItem,
  GoldenLayout,
  ItemType,
  LayoutConfig,
  LayoutManager,
  ResolvedComponentItemConfig,
  ResolvedLayoutConfig,
  Side,
  Stack,
  Tab,
} from "golden-layout";
import { GoldenLayoutService } from "src/app/services/golden-layout.service";
import { MenuSettings } from "src/app/menusettings";
import { Route, Router } from "@angular/router";
import { AccountService, MenuService } from "@overa/security";
import { NavbarComponent, SidebarComponent } from "@overa/base";
import { ConfigsService } from "@overa/shared";

const INITIAL_LAYOUT: LayoutConfig = {
  root: {
    type: ItemType.stack,
    content: [],
  },
  header: {
    show: Side.top,
    //close: false, //if false break drag and close last stack tab
    popout: false,
  },
  settings: {
    //hasHeaders: true,
    constrainDragToContainer: true,
    reorderEnabled: true,
    //selectionEnabled: true,
    popoutWholeStack: false,
    blockedPopoutsThrowError: true,
    closePopoutsOnUnload: true,
    //showPopoutIcon: false,
    //showMaximiseIcon: true,
    //showCloseIcon: false,
  },
  /* labels: {
    popout: 'Abrir en nueva ventana',
    minimise: 'Minimizar',
    maximise: 'Maximizar',
    close: 'Cerrar'
  }, */
  dimensions: {
    borderWidth: 5,
    minItemHeight: 200,
    minItemWidth: 300,
    headerHeight: 30,
    dragProxyWidth: 300,
    dragProxyHeight: 200,
  },
};

@Component({
  selector: "app-golden-layout-host",
  template: "<ng-template #componentViewContainer></ng-template>",
  styles: [],
})
export class GoldenLayoutComponent implements OnDestroy {
  private _goldenLayout!: GoldenLayout;
  private _goldenLayoutElement: HTMLElement;
  private _componentRefMap = new Map<ComponentContainer, ComponentRef<any>>();
  private _disabledTabsHeaders: boolean = false;

  private _goldenLayoutBindComponentEventListener = (
    container: ComponentContainer,
    itemConfig: ResolvedComponentItemConfig
  ) => this.handleBindComponentEvent(container, itemConfig);
  private _goldenLayoutUnbindComponentEventListener = (
    container: ComponentContainer
  ) => this.handleUnbindComponentEvent(container);

  allwaysNewTabOnGL: boolean = this.config.getModuleConfig(
    "overa-shared",
    "allwaysNewTabOnGL"
  );
  useGoldenLayout: boolean = this.config.getModuleConfig(
    "overa-shared",
    "useGoldenLayout"
  );
  navigationFix = false;
  //isGuidOrNew = false;
  showHome = true;

  @ViewChild("componentViewContainer", { read: ViewContainerRef, static: true })
  private _componentViewContainerRef!: ViewContainerRef;
  @Input() sidebar!: SidebarComponent;
  @Input() navbar!: NavbarComponent;
  @Input() homeContainer!: HTMLElement;
  @Input() routerOuletContainer!: HTMLElement;
  @Input() goldenLayoutHostContainer!: HTMLElement;

  get goldenLayout() {
    return this._goldenLayout;
  }

  constructor(
    private _appRef: ApplicationRef,
    private _elRef: ElementRef<HTMLElement>,
    private goldenLayoutService: GoldenLayoutService,
    private renderer: Renderer2,
    private router: Router,
    private menuService: MenuService,
    private accountService: AccountService,
    private config: ConfigsService
  ) {
    this._goldenLayoutElement = this._elRef.nativeElement;

    MenuSettings.MENU.routes?.forEach((menuItem) => {
      this.goldenLayoutService.registerComponentsRecursively([menuItem]);
      menuItem.children?.forEach((child) => {
        this.goldenLayoutService.registerComponentsRecursively([child]);
      });
    });
    //this.goldenLayoutService.registerComponentsLazy();

    //console.log("RegisteredGLComponents", this.goldenLayoutComponentService.getRegisteredComponentTypeNames());
  }

  ngOnDestroy() {
    this._goldenLayout.destroy();

    //Remove window resize listener
    window.removeEventListener("resize", this.onWindowResize.bind(this));
  }

  initialise() {
    this._goldenLayout = new GoldenLayout(
      this._goldenLayoutElement,
      this._goldenLayoutBindComponentEventListener,
      this._goldenLayoutUnbindComponentEventListener
    );

    MenuSettings.MENU.routes?.forEach((menuItem) => {
      this.goldenLayoutService.registerComponentsRecursively([menuItem]);
      menuItem.children?.forEach((child) => {
        this.goldenLayoutService.registerComponentsRecursively([child]);
      });
    });

    this.goldenLayoutEvents();

    this.loadSavedLayoutState();
    this.showOrHideGoldenLayout();
  }

  private loadSavedLayoutState() {
    const savedState = localStorage.getItem("goldenLayoutState");

    if (savedState) {
      try {
        const state = JSON.parse(savedState);
        const layoutToRestore = LayoutConfig.fromResolved(state);
        this._goldenLayout.loadLayout(layoutToRestore);
      } catch (e) {
        //Throws error when one of its components is from uiLib
        console.error("Error restoring layout state:", e);
        this._goldenLayout.loadLayout(INITIAL_LAYOUT);
      }
    } else {
      this._goldenLayout.loadLayout(INITIAL_LAYOUT);
    }
  }

  getComponentRef(container: ComponentContainer) {
    return this._componentRefMap.get(container);
  }

  private handleBindComponentEvent(
    container: ComponentContainer,
    itemConfig: ResolvedComponentItemConfig
  ): ComponentContainer.BindableComponent {
    const componentType = itemConfig.componentType;
    const componentRef = this.goldenLayoutService.createComponent(
      componentType,
      container
    );
    const component = componentRef!.instance;

    this._componentRefMap.set(container, componentRef!);

    this._appRef.attachView(componentRef!.hostView);
    const domElem = (componentRef!.hostView as EmbeddedViewRef<unknown>)
      .rootNodes[0] as HTMLElement;
    container.element.appendChild(domElem);

    return {
      component,
      virtual: false,
    };
  }

  private handleUnbindComponentEvent(container: ComponentContainer) {
    const componentRef = this._componentRefMap.get(container);
    if (componentRef === undefined) {
      throw new Error("Could not unbind component. Container not found");
    }
    this._componentRefMap.delete(container);

    const hostView = componentRef.hostView;

    const component = componentRef.instance;
    const componentRootElement = component.rootHtmlElement;
    if (componentRootElement instanceof Node)
      container.element.removeChild(componentRootElement);
    this._appRef.detachView(hostView);

    componentRef.destroy();
  }

  openInGoldenLayout(currentMenuView: any, disabledTabsHeaders?: boolean) {
    if (this._goldenLayout && !this.navigationFix) {
      if (disabledTabsHeaders) {
        this._disabledTabsHeaders = disabledTabsHeaders;
      }

      if (disabledTabsHeaders) {
        let disabledTabsHeadersLayout = structuredClone(INITIAL_LAYOUT);
        disabledTabsHeadersLayout.header!.show = false;
        this._goldenLayout.loadLayout(disabledTabsHeadersLayout);
      } else {
        this.loadSavedLayoutState();
      }

      //const currentView = currentMenuView.uiUrl?.split('/').pop() || '';
      const path = currentMenuView.uiUrl || currentMenuView.path;
      const currentView = path?.startsWith("/")
        ? path?.substring(1)
        : path || "";

      //TODO: Añadir traducciones
      const viewName = currentMenuView.name;

      //if (!this.isGuidOrNew) {
      this.showHome = false;

      const components: ComponentItem[] = [];
      this.goldenLayoutService.findStacksAndComponentsRecursively(
        (this._goldenLayout as any).groundItem,
        [],
        components
      );

      const focusedItem = components.find(
        (c) => (c.container.state as any).focused == true
      );
      if (focusedItem) this._goldenLayout.focusComponent(focusedItem, false);

      const currentActiveItem = this._goldenLayout.focusedComponentItem;
      // console.log("navbar", this.navbar);
      // console.log("sidebar", this.sidebar);
      if (
        !this.navbar?.isCtrlNavigation &&
        !this.sidebar?.isCtrlNavigation &&
        !this.allwaysNewTabOnGL &&
        currentActiveItem != null &&
        !(currentActiveItem.container.state as any).locked
      ) {
        const newComponent = this._goldenLayout.newComponentAtLocation(
          currentView,
          null,
          //currentView,
          viewName,
          [
            {
              typeId: LayoutManager.LocationSelector.TypeId.FocusedItem,
              index: 0,
            },
          ]
        );
        this._goldenLayout.focusedComponentItem?.parent?.removeChild(
          currentActiveItem!
        );
      } else {
        this._goldenLayout.addComponent(currentView, null, viewName);
      }
      //}
      if (this.sidebar) {
        this.sidebar.isCtrlNavigation = false;
      }
      if (this.navbar) {
        this.navbar.isCtrlNavigation = false;
      }
    } else {
      this.navigationFix = false;
    }
  }

  goldenLayoutEvents() {
    /* this._goldenLayout.on('__all', (event: any) => {
      console.log("golden event __all -> ", event);
    });
 */
    this._goldenLayout.on("tabCreated", (newTab: Tab) => {
      // console.log('golden event tabCreated -> ', newTab);
      if (!this.allwaysNewTabOnGL) {
        this.blockTab(newTab.componentItem, false);
      } else {
        this.renderer.addClass(newTab.componentItem.tab.element, "noLock");
      }
      this.focusTab(newTab, true);
    });

    this._goldenLayout.on("itemCreated", (event: any) => {
      // console.log('golden event itemCreated -> ', event);
      if (event.origin.type == "component") {
        this.renderer.listen(event.target.element, "click", (ev) => {
          this.selectItemAndNavigate(event.target, event.target.componentName);
        });
      }
    });

    this._goldenLayout.on(
      "activeContentItemChanged",
      (activeContentItem: ComponentItem) => {
        // console.log('golden event activeContentItemChanged -> ', activeContentItem);
        this._goldenLayout.focusComponent(activeContentItem, false);
      }
    );

    this._goldenLayout.on("focus", (event: any) => {
      // console.log('golden event focus -> ', event.target);

      if (event.target.type == "component") {
        this.selectItemAndNavigate(event.target, event.target.componentName);
      }
    });

    this._goldenLayout.on("itemDropped", (item) => {
      // console.log('golden event itemDropped -> ', item);
      this.blockTab(item, false);
      this.focusTab(item.tab, false);
    });

    this._goldenLayout.on("itemDestroyed", (event: any) => {
      // console.log('golden itemDestroyed -> ', event, this._goldenLayout);
      if (event.target.type == "stack") {
        // console.log('golden itemDestroyed stack');

        const stacks: Stack[] = [];
        this.goldenLayoutService.findStacksAndComponentsRecursively(
          event.target.layoutManager.groundItem,
          stacks,
          []
        );

        const otherStacks = stacks.filter((f: any) => f != event.target);
        if (otherStacks.length > 0) {
          let focusedStack = otherStacks.find((f: any) => f.focused);
          if (!focusedStack) {
            focusedStack = otherStacks.pop();
          }
          const focusedStackItems =
            focusedStack?.contentItems as ComponentItem[];
          const prevStackActiveItem = focusedStackItems?.find(
            (p: ComponentItem) => p.tab.isActive == true
          );
          if (prevStackActiveItem) {
            this.selectItemAndNavigate(
              prevStackActiveItem.tab.componentItem,
              (prevStackActiveItem.tab.componentItem as any).componentName
            );
          }
        }

        if (this._goldenLayout.rootItem?.contentItems.length == 1) {
          const stacks = this.goldenLayout.rootItem?.contentItems.filter(
            (s) => s.contentItems.length != 0
          ) as any;
          this.selectItemAndNavigate(
            stacks[0],
            stacks[0]?._activeContentItem.componentName
          );
        }
      }
      if (event.target.type == "component") {
        // console.log('golden itemDestroyed component', this._goldenLayout);
        // let components = this.goldenLayoutRoot.getItemsByType("component") as any;
        // //console.log("golden itemDestroyed components remaining -> ", components);
        // if (components.length == 1) {
        //   //console.log("golden last itemDestroyed disable maximise", this.goldenLayoutInstance);
        //   //this.goldenLayoutInstance.config.settings.showMaximiseIcon = false;
        //   //this.renderer.setAttribute(components[0].parent.header.element,'style', 'display:none');
        //   this.renderer.setAttribute(this.goldenLayoutRoot.element, 'style', 'display:none');
        //   this.showHome = true;
        //   this.navigationFix = true;
        //   this.router.navigateByUrl("home");
        //   this.menuService.updateMainRoute(null);
        // }
      }
      this.showOrHideGoldenLayout();
    });

    // Save goldenLayoutState but not when tabs headers are disabled
    this._goldenLayout.on("stateChanged", () => {
      if (this._goldenLayout.isInitialised && !this._disabledTabsHeaders) {
        const state = this._goldenLayout.saveLayout();
        // state = this.ensureSizeUnits(state);
        localStorage.setItem("goldenLayoutState", JSON.stringify(state));
      }
    });

    // Add listener for browser window resize
    window.addEventListener("resize", this.onWindowResize.bind(this));
  }

  /**
   * Handler for browser window resize.
   * It resizes the golden layout.
   */
  onWindowResize(): void {
    if (this._goldenLayout) {
      const width = this._elRef.nativeElement.clientWidth;
      const height = this._elRef.nativeElement.clientHeight;
      this._goldenLayout.setSize(width, height);
    }
  }

  showOrHideGoldenLayout() {
    const tabs =
      (this._goldenLayout as any).root.element.querySelectorAll(".lm_tab")
        .length > 0;
    if (!tabs) {
      /* if(this.routerOuletContainer.hasChildNodes()){
        this.routerOuletContainer.style.display = "block";
      } 
      else*/ if (this.homeContainer) {
        this.homeContainer.style.display = "block";
      }

      this.goldenLayoutHostContainer.style.zIndex = "-1";
    } else {
      if (this.homeContainer) {
        this.homeContainer.style.display = "none";
      }

      this.goldenLayoutHostContainer.style.zIndex = "1";
    }
  }

  blockTab(content: any, reset: boolean) {
    // console.log('blockTab ', content, reset);
    const existTabBloker =
      content.tab.element.querySelector(".tabBlocker") !== null;
    if (!existTabBloker) {
      const blockIconDiv = this.renderer.createElement("div");
      this.renderer.setAttribute(blockIconDiv, "class", "tabBlocker");
      const blockIcon = this.renderer.createElement("span");
      this.renderer.setAttribute(
        blockIcon,
        "class",
        "tabBlockIcon pi pi-lock-open"
      );
      this.renderer.appendChild(blockIconDiv, blockIcon);
      content.tab.element.append(blockIconDiv);

      this.renderer.listen(blockIconDiv, "click", (event) => {
        this.blockTab(content.tab.contentItem, true);
        this._goldenLayout.focusComponent(content.tab.contentItem, false);
      });
    }

    const blockIcon = content.tab.element.lastChild.firstChild;
    if (reset) {
      if (content.container._state.locked) {
        content.container._state.locked = false;
      } else {
        content.container._state.locked = true;
      }
    }

    if (content.container._state.locked) {
      this.renderer.setAttribute(
        blockIcon,
        "class",
        "tabBlockIcon pi pi-unlock"
      );
    } else {
      this.renderer.setAttribute(
        blockIcon,
        "class",
        "tabBlockIcon pi pi-lock-open"
      );
    }
  }

  focusTab(tab: any, isInit = true) {
    // console.log('focusTab ', tab);
    if (isInit) {
      const activeIconDiv = this.renderer.createElement("div");
      const activeIcon = this.renderer.createElement("span");
      this.renderer.setAttribute(
        activeIcon,
        "class",
        "tabActiveIcon pi pi-eye"
      );
      this.renderer.appendChild(activeIconDiv, activeIcon);
      tab.element.prepend(activeIconDiv);
    }

    const components: ComponentItem[] = [];
    tab.componentItem.layoutManager.groundItem.contentItems.forEach(
      (item: any) => {
        this.goldenLayoutService.findStacksAndComponentsRecursively(
          item,
          [],
          components
        );
      }
    );

    const focusedComponents = components.filter((c) =>
      (c.tab.element.firstChild as HTMLElement)?.classList.contains("tabActive")
    );
    focusedComponents.forEach((focusedComponent) => {
      this.renderer.setAttribute(
        focusedComponent.tab.element.firstChild,
        "class",
        "tabFocus tabActiveHidden"
      );
      (focusedComponent.tab.componentItem.container.state as any).focused =
        false;
    });

    if (tab.componentItem.container._state.focused || !isInit) {
      tab.componentItem.container._state.focused = true;
      //tab.componentItem._focused = true;
      //this._goldenLayout.focusComponent(tab.componentItem, false);
      this.renderer.setAttribute(
        tab.element.firstChild,
        "class",
        "tabFocus tabActive"
      );
    } else {
      tab.componentItem.container._state.focused = false;
      //tab.componentItem._focused = false;
      this.renderer.setAttribute(
        tab.element.firstChild,
        "class",
        "tabFocus tabActiveHidden"
      );
    }
  }

  selectItemAndNavigate(item: any = null, itemName = "") {
    console.log("item and itemName", item, itemName);
    //console.log("golden selectItemAndNavigate -> ", item, navigateTo);
    if (!item && itemName == "") {
      item = this._goldenLayout.focusedComponentItem;
      itemName = item.componentName;
    }
    const menuItems = this.accountService.currentUserMenus;
    const navigateTo = menuItems.find(
      (r) => r.uiUrl?.split("/").pop() == itemName
    )?.uiUrl;
    if (item && navigateTo) {
      this.navigationFix = true;
      this.router.navigate([navigateTo]).then((x: any) => {
        const menuItems = this.sidebar.getCurrentMenu();
        console.log("MENUITEMS", menuItems);
        if (menuItems) {
          this.menuService.updateMainRoute(menuItems);
        }
      });
    }
    this._goldenLayout.focusComponent(item, false);
    this.focusTab(item.tab, false);
    this.showOrHideGoldenLayout();
  }

  /* mapUrl(url: string) {
    if (url.startsWith('/')) {
      url = url.substr(1);
    }
    let segments = url.split('/');
    let pattern = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
    let lastSegment = [...segments].pop();
    //console.log("mapUrl ->",lastSegment);
    let isGuid = pattern.test(lastSegment!);
    let isNew = lastSegment!.toLowerCase() == 'new';

    if (isGuid || isNew) {
      this.isGuidOrNew = true;
      if (url.includes('/')) {
        segments.pop();
        url = segments.join('/');
      }
    } else {
      this.isGuidOrNew = false;
    }
    //console.log("golden isNewOrGuid-> ", this.isGuidOrNew);

    // if (url.includes('/')) {
    //   if (isGuid || isNew ) {
    //     segments.pop();
    //     url = segments.join('/');
    //   }
    // }
    return url;
  } */
}
