import { AfterViewInit, Component } from "@angular/core";
import { ActivityBaseComponent } from "../activity-base.component";
import { Filters } from "src/app/models/filters.model";
import { ActivityCommandsService } from "src/app/services/activityCommands.service";
import { ActivityCommand } from "src/app/models/activityCommand.model";
import { OveraDialogRef, OveraDialogService } from "@overa/components/dialog";
import { ControlCommand } from "src/app/models/controlCommand.model";
import { TenantIdTenantUidService } from "src/app/services/tenantIdTenantUidService";
import {
  AccountService,
  BaseFormComponent,
  Hotkeys,
  SecurityStateManagementService,
} from "@overa/security";
import { ControlCommandRecipient } from "src/app/models/controlCommandRecipient.model";
import { CtlCommand } from "src/app/models/ctlCommand.model";
import {
  ActivityCommandFieldType,
  BooleanOption,
  booleanOptions,
} from "src/app/models/activityCommandField.model";
import { ActivityCommandValue } from "src/app/models/activityCommandValue.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

//TODO: Ver si se puede usar como clase base BaseFormComponent
@Component({
  selector: "app-overa-command-console",
  templateUrl: "./overa-command-console.component.html",
  styleUrls: ["./overa-command-console.component.scss"],
  providers: [OveraDialogRef],
})
//extends BaseFormComponent<ActivityCommand>
export class OveraCommandConsoleComponent
  extends ActivityBaseComponent
  implements AfterViewInit
{
  dialog?: OveraDialogRef;

  taskName: string = "";
  tenantUid: string = "";
  optionsCommandsDropdown: ActivityCommand[] = [];
  booleanOptions: any[] = booleanOptions;
  selectedCommand?: ActivityCommand;
  selectedHosts: string[] = [];

  formGroup: FormGroup;

  constructor(
    //public override accountService: AccountService,
    //public override router: Router,
    //public override hotkeys: Hotkeys,
    protected accountService: AccountService,
    protected SSMService: SecurityStateManagementService,
    protected activityCommandsService: ActivityCommandsService,
    protected dialogService: OveraDialogService,
    protected tenantService: TenantIdTenantUidService,
    private formBuilder: FormBuilder
  ) {
    super();
    //super(accountService, router, hotkeys);

    this.dialog = this.dialogService.dialogRef;

    this.formGroup = this.formBuilder.group({});
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.getTenantUid();
  }

  /* override ngAfterViewInit(): void {
    this.loadData();
  } */

  loadData(filters: Filters): void {
    this.selectedHosts = filters["hostNames"];
    this.initCommandsDropdown();
  }

  private initCommandsDropdown(selectedCommandId?: string) {
    this.activityCommandsService.getActivityCommands().subscribe({
      next: (res) => {
        this.optionsCommandsDropdown = res;
      },
      complete: () => {
        if (selectedCommandId) {
          // TODO: Seleccionar comando en el dropdown si se abrió el cuadro de diálogo con un id
        }
      },
    });
  }

  createControlCommand() {
    const recipients: ControlCommandRecipient[] = this.selectedHosts?.map(
      (host) => ({ clientId: host.toUpperCase() })
    );

    const controlCommand: ControlCommand = {
      description: this.taskName ?? this.selectedCommand?.publicName,
      commandType: this.selectedCommand?.privateName,
      tenantUid: this.tenantUid,
      data: this.createCommandData(this.selectedCommand!),
      controlCommandRecipients: recipients,
      commandId: this.selectedCommand?.id,
    };
    console.log(controlCommand);
  }

  closeDialog() {
    this.dialog?.close();
  }

  getTenantUid() {
    const tenantId = this.accountService.currentTenant?.id;
    if (tenantId) {
      this.tenantService.getTenantById(tenantId).subscribe({
        next: (res) => (this.tenantUid = res.tenantUid!),
      });
    }
  }

  createCommandData(selectedCommand: ActivityCommand): string {
    const ctlCommand: CtlCommand = {
      command: selectedCommand.privateName,
      data: {},
    };

    const fields = selectedCommand.fields;

    if (!selectedCommand.sendDataVoid) {
      if (fields.length == 0) {
        delete ctlCommand.data;
      } else {
        if (selectedCommand.dataIsSimpleParameter) {
          // Solo aplica el valor del primer campo en caso de que el comando tenga varios
          const value = this.formGroup
            ? this.formGroup.get(fields[0].name)?.value
            : fields[0].values;
          ctlCommand.data = value;
        } else {
          fields.forEach((field) => {
            const value = this.formGroup
              ? this.formGroup.get(field.name)?.value
              : field.values;

            switch (field.type) {
              case ActivityCommandFieldType.INPUT:
              case ActivityCommandFieldType.TEXT: {
                ctlCommand.data[field.name] = value;
                break;
              }
              case ActivityCommandFieldType.MULTI: {
                const valuesAsStrings = (value as ActivityCommandValue[]).map(
                  (object) => object.value
                );
                ctlCommand.data[field.name] = valuesAsStrings;
                break;
              }
              case ActivityCommandFieldType.DROPDOWN: {
                ctlCommand.data[field.name] = (
                  value as ActivityCommandValue
                )?.value;
                break;
              }
              case ActivityCommandFieldType.NUMBER: {
                const number = Number(value);
                ctlCommand.data[field.name] = !isNaN(number) ? number : 0;
                break;
              }
              case ActivityCommandFieldType.BOOLEAN: {
                if (typeof value == "string") {
                  ctlCommand.data[field.name] = value.toLowerCase() == "true";
                }
                ctlCommand.data[field.name] = (value as BooleanOption).value;
                break;
              }
              case ActivityCommandFieldType.JSON: {
                ctlCommand.data[field.name] = JSON.parse(value as string);
                break;
              }
              default: {
                ctlCommand.data[field.name] = value;
              }
            }
          });
        }
      }
    }

    if (typeof ctlCommand.data === "object") {
      ctlCommand.data = JSON.stringify(ctlCommand.data);
    }

    return JSON.stringify(ctlCommand);
  }

  private minifyJSON(jsonString: string): string {
    // Expresión regular que encuentra espacios en blanco fuera de comillas
    // La expresión lookahead asegura que lo que sigue es cualquier cantidad
    // de espacios en blanco o el final de la cadena
    const regex = /(".*?"|'.*?'|[^ \n\r\t]+)(?=\s*|\s*$)/g;
    return jsonString.match(regex)?.join("") || jsonString;
  }

  createFormGroup() {
    // Eliminar controles existentes
    Object.keys(this.formGroup.controls).forEach((controlName) => {
      this.formGroup.removeControl(controlName);
    });

    this.selectedCommand?.fields.forEach((field) => {
      if (!this.selectedCommand?.sendDataVoid) {
        const initialValue: any = field.values;

        const validators = field.isRequired ? [Validators.required] : [];
        this.formGroup.addControl(
          field.name,
          this.formBuilder.control(
            { value: initialValue, disabled: field.isFixed },
            validators
          )
        );
      }
    });
  }
}
